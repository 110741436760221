import {loadState} from '../store/localStorage';
import _ from 'lodash';

export const getBaseRequestConfig = () => {

  const state = loadState();

  let config = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (state && _.has(state, 'loggedUser.token')) {
    config = Object.assign({}, config, {
        headers: {
            ...config.headers,
          Authorization: `Bearer ${state.loggedUser.token}`,
        },
    });
  }

  return config;
};
