import { combineReducers } from 'redux';
import {routerReducer} from 'react-router-redux';
import {FormStateMap, reducer as formReducer} from 'redux-form';
import {IRequestState, request} from './request';
import {IMenuState, menu} from './menu';
import {ICaptchaState, captcha} from './captcha';

const rootReducer = combineReducers({
      routing: routerReducer,
      form: formReducer,
      request,
      menu,
      captcha,
});

export default rootReducer;

export interface IAppState {
      readonly form: FormStateMap;
      readonly request: IRequestState;
      readonly menu: IMenuState;
      readonly captcha: ICaptchaState;
}
