import React, {useEffect, useRef} from 'react';
import Page from '../../../core/components/Page';
import {colors, Container, Grid, makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {changeMenuState} from '../../../core/actions/menu';
import {IAppState} from '../../../core/reducers';
import Footer from '../../Footer';
import CooperationForm, {CooperationFormData} from '../../components/forms/CooperationForm';
import {baseRequestAction} from '../../../core/actions/request';
import {sendCooperationForm} from '../../api/api.homepage';
import {Notification} from '../../../core/components/notification/Notify';
import {reset} from 'redux-form';
import {scroller} from 'react-scroll';

let timeout;

function AboutUsPage() {
    const classes = HomePageStyles({});
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const menu = useSelector((state: IAppState) => state.menu);
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            if (scrollRef.current) {
                const elementTopPosition = scrollRef.current.offsetTop;
                const windowScrollTopPosition = window.scrollY;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    if (windowScrollTopPosition >= elementTopPosition) {
                        if (menu.fixed === true) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: true,
                        }));
                    } else {
                        if (menu.fixed === false) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: false,
                        }));
                    }
                }, 5);
            }
        });
    }, [menu]);

    const formSubmit = (formData: CooperationFormData) => {
        dispatch(baseRequestAction({
            requestFrom: 'cooperationForm',
            onRequestStarted: async (data) => {
                return await sendCooperationForm(formData.email);
            },
            onRequestSuccess: (data) => {
                if (data.response.send) {
                    Notification.createNotification('Odesláno!', {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                        variant: 'success',
                    });
                } else {
                    Notification.createNotification('Tento email nám byl již zaslán!', {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                        autoHideDuration: 10000,
                        variant: 'info',
                    });
                }

                dispatch(reset('CooperationForm'));
            },
        }));
    };

    return <Page
        title={'About'}
    >
        <div className={classes.root}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    className={classes.gridRoot}
                    container
                    direction='column'
                    justify='center'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.rootTextTitle}>
                        Jsme...
                    </Grid>
                    <Grid item className={classes.rootTextTitleTwo}>
                        Tým spolehlivých lidí.
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.divider} ref={scrollRef}>
            <div className={classes.dividerWave}>
                <img src={'/images/home/vlnka.png'} style={{maxWidth: '100%'}}/>
                <img className={classes.dividerArrow} src={'/images/home/sipky.png'} onClick={() => {
                    scroller.scrollTo('arrowClick', {
                        duration: 1000,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                    });
                }}/>
            </div>
        </div>
        <div className={classes.searchWorkerRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.searchWorkerTitle} xs={12}>
                        NÁŠ CÍL? SPOKOJENÝ ZÁKAZNÍK.
                    </Grid>
                    <Grid item className={classes.searchWorkerDescription} xs={12}>
                        Naší hlavní náplní je tvorba aplikací. Rádi zkoušíme nové technologie, baví nás inovace a nebojíme se výzev. Neustále na sobě pracujeme a zdokonalujeme se, je pro nás samozřejmostí přinést ti to nejlepší možné řešení.
                        Když je appka hotová, práce pro nás nekončí. Zakládáme si na dlouhodobých obchodních vztazích.
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.startupRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        className={classes.startupText}
                        xs={12}
                        md={4}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                    >
                       <Grid item className={classes.startupTextTitle} xs={12}>
                           Rozjíždíš startup?
                       </Grid>
                        <Grid item className={classes.startupTextDescription} xs={12}>
                            Pomůžeme ti s realizací softwarové aplikace, o kterou se postaráme od A do Z, vč. samotného provozu.
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.startupImageWrapped}
                        xs={12}
                        md={8}
                    >
                        <img src={'/images/kdo_jsme/obrazek stratup.png'} style={{maxWidth: '100%'}}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.searchRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid item xs={12} className={classes.searchTitle}>
                        ZAUJALI JSME TĚ?
                    </Grid>
                    <Grid item xs={12} className={classes.searchDescription}>
                        Ozvi se nebo nám na sebe zanech mail.
                    </Grid>
                    <Grid item xs={12} className={classes.searchForm}>
                        <CooperationForm onSubmit={formSubmit.bind(this)}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <Footer/>
    </Page>;
}

const HomePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            height: '485px',
            fontFamily: 'century-gothic',
            backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
            color: colors.common.white,
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
        },
        rootTextTitle: {
            fontWeight: 'bold',
            fontSize: 42,
            zIndex: 2,
        },
        rootTextTitleTwo: {
            paddingTop: '10px',
            fontWeight: 'bold',
            fontSize: 33,
            zIndex: 2,
        },
        rootTextDescription: {
            marginTop: theme.spacing(3),
            fontSize: 18,
            zIndex: 2,
        },
        gridRoot: {
            'fontFamily': 'century-gothic',
            'height': '100%',
            'position': 'relative',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kdo_jsme/puzzle.png)',
                position: 'absolute',
                left: 0,
                right: '-630px',
                top: '100px',
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                },
            },
        },
        divider: {
            height: '13px',
            backgroundColor: colors.common.white,
            width: '100%',
            position: 'relative',
        },
        dividerWave: {
            bottom: 7,
            zIndex: 2,
            width: '100%',
            maxWidth: '293px',
            position: 'absolute',
            right: 0,
            left: 0,
            margin: 'auto',
        },
        dividerArrow: {
            position: 'absolute',
            maxHeight: '50%',
            right: 0,
            top: 22,
            left: 0,
            margin: 'auto',
        },
        searchWorkerRoot: {
            paddingBottom: '20px',
            fontFamily: 'century-gothic',
            minHeight: '265px',
            width: '100%',
            backgroundColor: colors.common.white,
        },
        searchTextWorkerGrid: {
            paddingTop: theme.spacing(8),
            height: '100%',
            position: 'relative',
            textAlign: 'end',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        searchWorkerTitle: {
            textTransform: 'uppercase',
            paddingTop: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 21,
            zIndex: 2,
            color: '#343434',
        },
        searchWorkerDescription: {
            fontSize: 21,
            textAlign: 'justify',
            paddingTop: theme.spacing(3),
            // paddingLeft: theme.spacing(5),
            // paddingRight: theme.spacing(5),
            zIndex: 2,
            color: '#343434',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        startupRoot: {
            'overflow': 'hidden',
            'fontFamily': 'century-gothic',
            'minHeight': '410px',
            'width': '100%',
            'backgroundColor': '#f2f2f2',
            'paddingBottom': '100px',
            'position': 'relative',
            '&::before' : {
                zIndex: 1,
                backgroundSize: '100% 100%',
                backgroundImage: 'url(/images/kdo_jsme/seda_vlnka.png)',
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '300px',
                height: '100%',
                width: '100%',
            },
        },
        startupText: {
            zIndex: 2,
            paddingTop: '180px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingTop: '50px',
            },
        },
        startupTextTitle: {
            fontWeight: 'bold',
            fontSize: 33,
            zIndex: 2,
            color: '#966dff',
            textTransform: 'uppercase',
        },
        startupTextDescription: {
            paddingTop: '50px',
            fontSize: 21,
            color: '#343434',
        },
        startupImageWrapped: {
            zIndex: 2,
            paddingTop: '90px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingTop: '30px',
            },
        },
        searchRoot: {
            paddingTop: '60px',
            textAlign: 'center',
            color: colors.common.white,
            fontFamily: 'century-gothic',
            minHeight: '340px',
            width: '100%',
            backgroundColor: '#917bff',
            paddingBottom: '60px',
        },
        searchTitle: {
            fontWeight: 'bold',
            fontSize: 33,
            textTransform: 'uppercase',
        },
        searchDescription: {
            paddingTop: '15px',
            fontSize: 20,
        },
        searchForm: {
            paddingTop: '60px',
            paddingRight: '190px',
            paddingLeft: '190px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '80px',
                paddingLeft: '80px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: '20px',
                paddingLeft: '20px',
            },
        },
        teamRootOne: {
            paddingTop: '60px',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '500px',
            width: '100%',
            backgroundColor: '#f2f2f2',
            paddingBottom: '30px',
        },
        teamRootTwo: {
            paddingTop: '60px',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '560px',
            width: '100%',
            backgroundColor: colors.common.white,
            paddingBottom: '30px',
        },
        teamImageLeft: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                flexDirection: 'unset',
                alignItems: 'unset',
                textAlign: 'center',
            },
        },
        teamImage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                flexDirection: 'unset',
                alignItems: 'unset',
                textAlign: 'center',
            },
        },
        anet: {
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap-reverse',
            },
        },
        teamText: {
            paddingTop: '50px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        teamTextTitle: {
            fontWeight: 'bold',
            fontSize: 25,
        },
        teamTextShortDescription: {
            fontSize: 21,
            paddingTop: '10px',
        },
        teamTextDescription: {
            paddingTop: '30px',
        },
        teamTextEmailWrapped: {
            fontSize: 21,
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            display: 'flex',
            paddingTop: '60px',
            lineHeight: '60px',
        },
        teamTextEmailText: {
            paddingLeft: '15px',
            fontWeight: 'bold',
            fontSize: '20',
            [theme.breakpoints.down('sm')]: {
                fontSize: '3vw',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '5vw',
            },
        },
    }),
);

export default AboutUsPage;
