import {fork, all} from 'redux-saga/effects';
import * as baseRequest from './request';

export default function *rootSaga() {

  yield all([
    yield fork(baseRequest.watchBaseRequest),
    yield fork(baseRequest.watchRequestFailed),
  ]);

}
