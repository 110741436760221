import {Reducer} from 'react';

export interface ICaptchaState {
    activeCaptchaForm: string;
    countSend: number;
}

export const captcha: Reducer<ICaptchaState, any> = (
    state = {
        countSend: 0,
        activeCaptchaForm: undefined,
    },
    action,
) => {
    switch (action.type) {
        case 'REGISTER_CAPTCHA' :
            const {formName} = action.payload;
            return {...state, activeCaptchaForm: formName};
        case '@@redux-form/SET_SUBMIT_SUCCEEDED':
            if (state.activeCaptchaForm) {
                return {...state, countSend: state.countSend + 1};
            } else {
                return {...state};
            }
        case '@@redux-form/DESTROY' :
            return {...state, countSend: 0, activeCaptchaForm: undefined};
        default:
            return state;
    }
};
