import React from 'react';
import {Redirect} from 'react-router-dom';
import Error401 from '../bundles/error/pages/Error401';
import Error404 from '../bundles/error/pages/Error404';
import Error500 from '../bundles/error/pages/Error500';
import {Person, SvgIconComponent} from '@material-ui/icons';
import {RouteConfig} from 'react-router-config';
import Error from '../bundles/core/containers/Error';
import FrontPageContainer from '../bundles/core/containers/FrontPageContainer/FrontPageContainer';
import HomePage from '../bundles/homepage/pages/home/HomePage';
import AboutUsPage from "../bundles/homepage/pages/home/AboutUsPage";
import AppsPage from "../bundles/homepage/pages/home/AppsPage";
import CarrerPage from "../bundles/homepage/pages/home/CareerPage";

const AppRoutes: IAppRoutes[] = [
    {
        path: '/errors',
        // @ts-ignore
        component: Error,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: Error401,
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: Error404,
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: Error500,
            },
            {
                component: () => <Redirect to='/errors/error-404' />,
            },
        ],
    },
    {
        route: '*',
        component: FrontPageContainer,
        routes: [
            {
                path: '/',
                name: 'Úvod',
                exact: true,
                component: HomePage,
                Icon: Person,
            },
            {
                path: '/about-us',
                name: 'O nás',
                exact: true,
                component: AboutUsPage,
                Icon: Person,
            },
            {
                path: '/apps',
                name: 'Aplikace',
                exact: true,
                component: AppsPage,
                Icon: Person,
            },
            {
                path: '/career',
                name: 'Kariéra',
                exact: true,
                component: CarrerPage,
                Icon: Person,
            },
            {
                component: () => <Redirect to='/errors/error-404' />,
            },
        ],
    },
];

export interface IAppRoutes extends RouteConfig {
    Icon?: SvgIconComponent;
    disableBreadcrumb?: boolean;
    name?: string;
}

export default AppRoutes;
