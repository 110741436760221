import {Action} from 'redux-actions';
import {IHttpApiCallError} from '../connectivity/HttpApiCallError';
import GraphErrorObject from "../connectivity/graph/GraphErrorObject";

export interface IOnRequestStartedProp {
    requestFrom: string;
}

export interface IOnRequestSuccessProp {
    requestFrom: string;
    response: any;
}

export interface IOnRequestFailedProp {
    requestFrom: string;
    error: GraphErrorObject;
}

export interface IOnRequestFailedFormProp {
    formName: string;
    error: GraphErrorObject;
}

export interface IOnRequestFinishedProp {
    requestFrom: string;
}

export interface IBaseRequestAction {
    requestFrom: string;
    onRequestStarted: (data: IOnRequestStartedProp) => Promise<any>;
    onRequestSuccess?: (data: IOnRequestSuccessProp) => void;
    onRequestFailed?: (data: IOnRequestFailedProp) => void;
    onRequestFailedForm?: (data: IOnRequestFailedFormProp) => void;
    onRequestFinished?: (data: IOnRequestFinishedProp) => void;
}

export interface IRequestStartedAction extends Action<IBaseRequestAction> {
    type: 'REQUEST_STARTED';
}

export interface IRequestSuccessAction extends Action<IOnRequestSuccessProp> {
    type: 'REQUEST_SUCCESS';
}

export interface IRequestFailedAction extends Action<IOnRequestFailedProp> {
    type: 'REQUEST_FAILED';
}

export interface IRequestFailedFormAction extends Action<IOnRequestFailedFormProp> {
    type: 'REQUEST_FAILED_FORM';
}

export function requestFailedFormAction({error, formName}: IOnRequestFailedFormProp): IRequestFailedFormAction {
    return {
        type: 'REQUEST_FAILED_FORM', payload: {
            error, formName,
        },
    };
}

export interface IRequestFinishedAction extends Action<IOnRequestFinishedProp> {
    type: 'REQUEST_FINISHED';
}

export function baseRequestAction(
    {
        requestFrom,
        onRequestStarted,
        onRequestSuccess= () => {},
        onRequestFinished = () => {},
        onRequestFailed = () => {},
    }: IBaseRequestAction): IRequestStartedAction {
    return { type: 'REQUEST_STARTED', payload: {
            requestFrom,
            onRequestStarted,
            onRequestSuccess,
            onRequestFinished,
            onRequestFailed,
        },
    };
}
