import {put, takeEvery, call} from 'redux-saga/effects';
import {IRequestFailedAction, IRequestStartedAction} from '../actions/request';
import {Notification} from '../components/notification/Notify';

function *doRequestFailed({payload, type}: IRequestFailedAction ) {
    const {error, requestFrom} = payload;

    Notification.createNotification(error.message, {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        variant: 'error',
    });
}

function *doRequest({payload, type}: IRequestStartedAction ) {

    const {
        requestFrom,
        onRequestStarted,
        onRequestFailed,
        onRequestFinished,
        onRequestSuccess,
    } = payload;

    try {

        const response = yield call(onRequestStarted, {requestFrom});

        yield call(onRequestSuccess, {
            response,
            requestFrom,
        });

        yield put({
            type: 'REQUEST_SUCCESS',
            payload: {
                response,
                requestFrom,
            },
        });

    } catch (e) {

        yield put({
            type: 'REQUEST_FAILED',
            payload: {
                error: e,
                requestFrom,
            },
        });

        yield call(onRequestFailed, {
            error: e,
            requestFrom,
        });
    } finally {

        yield put({
            type: 'REQUEST_FINISHED',
            payload: {
                requestFrom,
            },
        });

        yield call(onRequestFinished, {
            requestFrom,
        });
    }
}

export function *watchBaseRequest() {
    yield takeEvery('REQUEST_STARTED', doRequest);
}

export function *watchRequestFailed() {
    yield takeEvery('REQUEST_FAILED', doRequestFailed);
}
