import {Button, colors, withStyles} from '@material-ui/core';

const MyPurpleButton = withStyles({
    root: {
        '&:hover': {
            backgroundColor: '#966dff',
        },
        lineHeight: '57px',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 'unset',
        backgroundColor: '#966dff',
        fontSize: 'unset',
        fontFamily: 'century-gothic',
        fontWeight: 'bold',
    },
    label: {
        fontSize: 21,
        color: colors.common.white,
        textTransform: 'uppercase',
    },
})(Button);

export default MyPurpleButton;
