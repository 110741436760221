import React from 'react';
import BaseFormComponent, {Field, IButton} from '../../../core/components/form/BaseForm';
import {reduxForm, Field as FormField} from 'redux-form';
import {connect, useDispatch} from 'react-redux';
import TextInput, {GetTextInputType} from '../../../core/components/form/components/TextInput';
import {email, required} from '../../../core/components/form/validation/baseValidation';
import {makeStyles} from '@material-ui/core/styles';
import {colors, Grid, GridProps, TextField} from '@material-ui/core';
import MyPurpleButton from '../../../core/themes/components/MyPurpleButton';
import MyPurpleDarkButton from '../../../core/themes/components/MyPurpleDarkButton';
import MyBlueButton from '../../../core/themes/components/MyBlueButton';
import {GetSelectType} from "../../../core/components/form/components/SelectInput";
import {GetCaptchaType} from "../../../core/components/form/components/Captcha";

class WantApplicationForm extends BaseFormComponent<WantApplicationFormData> {

    protected getContainerProps(): GridProps {
        return {
            // container: false,
            // xs: 6,
        };
    }

    protected getFields(): Field[] {

        const fields: Field[] = [
            {
                CustomRender: () => <CustomInput/>,
            },
        ];

        return fields;
    }

    protected getButtonConfig(): IButton {
        return {
            CustomRender: () => <CustomButton/>,
        };
    }
}

const CustomInput = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            paddingTop: '40px',
            paddingRight: '40px',
            paddingLeft: '40px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '15px',
                paddingRight: 0,
                paddingLeft: 0,
            },
        },
        labelClass: {
            fontFamily: 'century-gothic',
            fontSize: 16,
            top: '-50px',
            left: '-15px',
        },
        labelSelectClass: {
            fontFamily: 'century-gothic',
            fontSize: 16,
            top: '-50px',
            left: 0,
        },
        inputRoot: {
            borderRadius: 'unset',

            // 'label[data-shrink=false]': {
            //     opacity: '0.5 !important',
            // },

        },
    }));
    const classes = useStyles({});

    const typeComponentEmail = GetTextInputType(
        'email',
        {
            type: 'email',
            label: 'E-mail',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
            email,
        ],
    );

    const typeComponentName = GetTextInputType(
        'name',
        {
            label: 'Jméno a příjmení',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentPhone = GetTextInputType(
        'phone',
        {
            type: 'tel',
            label: 'Telefon',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentTypeApp = GetTextInputType(
        'typeApp',
        {
            label: 'TYP APLIKACE',
            variant: 'outlined',
            placeholder: 'Pokladní systém, fakturační systém, ....',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            inputProps: {
                className: classes.inputRoot,
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                placeholder: 'kus',

                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentDescription = GetTextInputType(
        'description',
        {
            multiline: true,
            rows: 5,
            rowsMax: 5,
            type: 'textarea',
            label: 'Poznámka',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const dispatch = useDispatch();
    dispatch({
        type: 'REGISTER_CAPTCHA',
        payload: {
            formName: 'CooperationForm',
        },
    });

    const typeCaptcha = GetCaptchaType(
            'captcha',
            {
                xs: 12,
            },
            [
                required,
            ],
        );

    //
    // // @ts-ignore
    // if (this.props.captcha.countSend >= config.reCaptcha.countTry) {
    //     fields = [
    //         ...fields,
    //         {
    //             typeComponent: GetCaptchaType(
    //                 'captcha',
    //                 {
    //                     xs: 12,
    //                 },
    //                 [
    //                     required,
    //                 ],
    //             ),
    //         },
    //     ];
    // }

    return (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeComponentName.validate}
                    {...typeComponentName}
                    component={typeComponentName.component}
                    name={typeComponentName.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeComponentEmail.validate}
                    {...typeComponentEmail}
                    component={typeComponentEmail.component}
                    name={typeComponentEmail.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeComponentPhone.validate}
                    {...typeComponentPhone}
                    component={typeComponentPhone.component}
                    name={typeComponentPhone.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeComponentTypeApp.validate}
                    {...typeComponentTypeApp}
                    component={typeComponentTypeApp.component}
                    name={typeComponentTypeApp.name}
                />
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeComponentDescription.validate}
                    {...typeComponentDescription}
                    component={typeComponentDescription.component}
                    name={typeComponentDescription.name}
                />
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <FormField
                    formName={'WantApplicationForm'}
                    validate={typeCaptcha.validate}
                    {...typeCaptcha}
                    component={typeCaptcha.component}
                    name={typeCaptcha.name}
                />
            </Grid>
        </React.Fragment>
    );
};

const CustomButton = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            dispay: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '32px',
            paddingBottom: '8px',
        },
        label: {
            textTransform: 'uppercase',
        },
        button: {
            paddingTop: '8px',
            paddingBottom: '8px',
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }));
    const classes = useStyles({});
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grid}>
                <MyBlueButton
                    style={{height: '100%'}}
                    classes={{
                        label: classes.label,
                        root: classes.button,
                    }}
                    fullWidth
                    // className={classes.saveButton}
                    type='submit'
                    variant='contained'
                >
                    ODESLAT
                </MyBlueButton>
            </Grid>
        </React.Fragment>
    );
};

export interface WantApplicationFormData {
    name: string;
    email: string;
    phone: string;
    typeApp: string;
    description: string;
    captcha: string;
}

export default connect(null)(
    reduxForm({
        enableReinitialize : true,
        form: 'WantApplicationForm',
    })(WantApplicationForm),
);
