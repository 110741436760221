import HttpApiCallError from './HttpApiCallError';

export default async function asyncFetch(url, requestConfig = {}) {

  const response = await fetch(url, requestConfig);
  const isSuccess = response.status >= 200 && response.status < 300;
  const contentType = response.headers.get('content-type');
  if (isSuccess) {
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        return await response.json();
      } catch (e) {
        return {};
      }
    } else {
      try {
        return await response.text();
      } catch (e) {
        return '';
      }
    }
  }
  const error = new HttpApiCallError(
      response.statusText,
      response.status,
  );
  if (contentType && contentType.indexOf('application/json') !== -1) {
    error.response = await response.json();
  } else {
    error.response = await response.text();
  }
  throw error;
}
