import React from 'react';
import {
    colors,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles, Toolbar,
    Typography
} from '@material-ui/core';
import {SvgIconComponent} from '@material-ui/icons';
import {Link as RouterLink} from 'react-router-dom';
import clsx, {ClassValue} from 'clsx';

const BaseDrawer = (props: IBaseDrawer) => {
    const classes = useBaseDrawerStyles({});
    const {open, onClose, lists, globalClickEvent} = props;

    return (
        <Drawer
            anchor='right'
            classes={{
                paper: classes.drawer,
                // root: classes.rootDrawer,
            }}
            // className={classes.drawer}
            elevation={1}
            onClose={onClose}
            open={open}
            variant='temporary'
        >
            <div
                className={classes.root}
            >
                <div className={classes.logo}>
                    <Link
                        className={clsx(classes.link, {
                        })}
                        component={RouterLink}
                        to='/'
                        variant='h5'
                        underline='none'
                    >
                        <img className={classes.image} src={'/images/home/logo.png'}/>
                    </Link>
                </div>
                {lists.map(({className, items}, key) => {
                    return (
                        <List
                            className={clsx(classes.list, className)}
                            key={key}
                        >
                            {items.map((
                                {
                                    link,
                                    text,
                                    customRender,
                                    Icon,
                                    action,
                                    isActive
                                }, key) => (
                                <React.Fragment key={key}>
                                    {customRender ?
                                        <React.Fragment>
                                            {customRender({globalClickEvent})}
                                        </React.Fragment>
                                        :
                                        <ListItem
                                            disableGutters
                                        >
                                            {Icon &&
                                            <ListItemIcon>
                                                <Icon/>
                                            </ListItemIcon>
                                            }
                                            <ListItemText
                                                className={classes.listItemText}
                                                disableTypography
                                                primary={(action ?  <Typography
                                                    className={clsx(classes.link, {
                                                        [classes.linkActive]: isActive ? isActive() : false,
                                                    })}
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                        globalClickEvent();
                                                        action();
                                                    }}
                                                    display='block'
                                                    noWrap
                                                    variant='h6'
                                                >
                                                    {text}
                                                </Typography> :  <Typography
                                                    component={RouterLink}
                                                    className={clsx(classes.link, {
                                                        [classes.linkActive]: isActive ? isActive() : false,
                                                    })}
                                                    onClick={() => {
                                                        globalClickEvent();
                                                    }}
                                                    display='block'
                                                    noWrap
                                                    to={link}
                                                    variant='h6'
                                                >
                                                    {text}
                                                </Typography>)}
                                            />
                                        </ListItem>
                                    }
                                </React.Fragment>
                            ))}
                        </List>
                    );
                })}
            </div>
        </Drawer>
    );
};

export interface BaseDrawerActions {
    globalClickEvent: () => void;
}

export interface IBaseDrawerItem {
    customRender?: React.FunctionComponent<BaseDrawerActions>;
    text?: string;
    link?: string;
    action?: () => void;
    isActive?: () => boolean;
    Icon?: SvgIconComponent;
}

export interface IBaseDrawerList {
    items: IBaseDrawerItem[];
    className?: ClassValue;
}

export interface IBaseDrawer {
    globalClickEvent: () => void;
    open: boolean;
    onClose: () => void;
    lists: IBaseDrawerList[];
}

const useBaseDrawerStyles = makeStyles((theme) => ({
    image: {
        maxWidth: '100%',
    },
    drawer: {
        width: 230,
        backgroundColor: '#859afe',
        textTransform: 'uppercase',
    },
    root: {

    },
    list: {
        padding: theme.spacing(1, 3),
    },
    link: {
        color: colors.common.white,
        fontFamily: 'century-gothic',
        fontWeight: 'bold',
    },
    linkActive: {
        color: '#5f4bc6',
    },
    listItemText: {
        marginRight: theme.spacing(1),
    },
    lastActivity: {
        whiteSpace: 'nowrap',
    },
    logo: {
        paddingTop: '20px',
        padding: theme.spacing(1, 3),
        display: 'flex',
        alignItems: 'center',
    },
}));

export default BaseDrawer;
