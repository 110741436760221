import {getBaseRequestConfig} from '../../core/connectivity/baseRequestConfig';
import asyncFetch from '../../core/connectivity/async-fetch';
import {WantApplicationFormData} from '../components/forms/WantApplicationForm';
import {CarrierFormData} from '../components/forms/CarrierForm';
import { getConfig } from '../../config';

export async function sendCooperationForm(email: string) {
    const config = getConfig();
    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = Object.assign({}, baseRequestConfig, {
        method: 'POST',
        body: JSON.stringify({
            email,
        }),
    });

    const url = config.apiUrl + `/dip/cooperation`;

    return await asyncFetch(url, requestConfig);
}

export async function sendWantApplicationForm(data: WantApplicationFormData) {
    const baseRequestConfig = getBaseRequestConfig();
    const config = getConfig();

    const requestConfig = Object.assign({}, baseRequestConfig, {
        method: 'POST',
        body: JSON.stringify({
            ...data,
        }),
        headers: {
            ...baseRequestConfig.headers,
            'g-recaptcha-token': data.captcha,
        },
    });

    const url = config.apiUrl + `/dip/want-application`;

    return await asyncFetch(url, requestConfig);
}

export async function sendCarrierForm(data: CarrierFormData) {
    const config = getConfig();
    const formData = new FormData();
    formData.append('description', data.description);
    formData.append('email', data.email);
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('attachments', data.attachments, data.attachments.name);

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = Object.assign({}, baseRequestConfig, {
        method: 'POST',
        body: formData,
        headers: {
            'g-recaptcha-token': data.captcha,
        },
    });

    const url = config.apiUrl + `/dip/career`;

    return await asyncFetch(url, requestConfig);
}
