import React, {useEffect, useRef} from 'react';
import Page from '../../../core/components/Page';
import {colors, Container, Grid, makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {changeMenuState} from '../../../core/actions/menu';
import {IAppState} from '../../../core/reducers';
import Footer from '../../Footer';
import MyPurpleButton from '../../../core/themes/components/MyPurpleButton';
import {scroller} from 'react-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import WantApplicationForm, {WantApplicationFormData} from '../../components/forms/WantApplicationForm';
import {baseRequestAction} from '../../../core/actions/request';
import {sendWantApplicationForm} from '../../api/api.homepage';
import {Notification} from '../../../core/components/notification/Notify';
import {reset} from 'redux-form';

let timeout;

function AppsPage() {
    const classes = HomePageStyles({});
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const menu = useSelector((state: IAppState) => state.menu);
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            if (scrollRef.current) {
                const elementTopPosition = scrollRef.current.offsetTop;
                const windowScrollTopPosition = window.scrollY;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    if (windowScrollTopPosition >= elementTopPosition) {
                        if (menu.fixed === true) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: true,
                        }));
                    } else {
                        if (menu.fixed === false) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: false,
                        }));
                    }
                }, 5);
            }
        });
    }, [menu]);

    const sliderSettings = {
        className: classes.iconsWrapped,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        variableWidth: true,
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 0,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1550,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
    };

    const handleSubmit = (formData: WantApplicationFormData) => {
        dispatch(baseRequestAction({
            requestFrom: 'WantApplication',
            onRequestStarted: async (data) => {
                return await sendWantApplicationForm(formData);
            },
            onRequestSuccess: (data) => {
                Notification.createNotification('Odesláno!', {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    variant: 'success',
                });

                dispatch(reset('WantApplicationForm'));
            },
            onRequestFailed: (data) => {
                // @ts-ignore
                if (data.error.response && data.error.response.message && Array.isArray(data.error.response.message)) {
                    // @ts-ignore
                    for (const e of data.error.response.message) {
                        Notification.createNotification(e, {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            variant: 'error',
                        });
                    }
                }
            },
        }));
    };

    return <Page
        title={'Apps'}
    >
        <div className={classes.root}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    className={classes.gridRoot}
                    container
                    direction='column'
                    justify='center'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.rootTextTitle}>
                        Aplikace...
                    </Grid>
                    <Grid item className={classes.rootTextTitleTwo}>
                        Vývoj aplikací na zakázku.
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.divider} ref={scrollRef}>
            <div className={classes.dividerWave}>
                <img src={'/images/home/vlnka.png'} style={{maxWidth: '100%'}}/>
                <img className={classes.dividerArrow} src={'/images/home/sipky.png'} onClick={() => {
                    scroller.scrollTo('arrowClick', {
                        duration: 1000,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                    });
                }}/>
            </div>
        </div>
        <div className={classes.searchWorkerRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.searchWorkerDescription} xs={12}>
                        Od zbytku trhu nás odlišuje především přístup ke klientům.
                        Protože vytváříme produkt, který bude sloužit tobě, bereme tě od začátku jako součást týmu.
                        Stále máš přístup k hotové části aplikace a můžeš ji testovat.
                        Díky tomu sám budeš ovlivňovat vývoj svého softwaru, a ten se tak snadno přizpůsobí tvým potřebám.
                        Tímto způsobem předcházíme případným neshodám a předělávkám, které mohou vzniknout, když ti někdo představí již hotovou aplikaci.
                        To se ti s námi ale nestane.
                        <br/>
                        <br/>
                        Můžeš se spolehnout na to, že vždy pracujeme jen s těmi nejvhodnějšími technologiemi, které máme dokonale v malíku.
                        Každý projekt je ale jiný a neexistuje žádný univerzální zázrak, proto konkrétní technické řešení volíme individuálně.
                    </Grid>
                </Grid>
            </Container>
            <Slider
                {...sliderSettings}
            >
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/symfony.png'} style={{maxWidth: '100%'}}/>
                </div>
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/nest.png'} style={{maxWidth: '100%'}}/>
                </div>
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/graphql.png'} style={{maxWidth: '100%'}}/>
                </div>
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/docker.png'} style={{maxWidth: '100%'}}/>
                </div>
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/react.png'} style={{maxWidth: '100%'}}/>
                </div>
                <div
                    className={classes.icon}
                >
                    <img src={'/images/aplikace/elastic.png'} style={{maxWidth: '100%'}}/>
                </div>
            </Slider>
        </div>
        <div className={classes.startupRoot} id={'arrowClick'}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        className={classes.startupText}
                        xs={12}
                        md={7}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                    >
                       <Grid item className={classes.startupTextTitle} xs={12}>
                           Nech si vytvořit aplikaci na zakázku
                       </Grid>
                        <Grid item className={classes.startupTextDescription} xs={12}>
                            Pověz nám o svém nápadu! Fantazii se meze nekladou, řekni nám tedy vše, co máš v hlavě, a my už se postaráme o to, aby se tvé vize staly skutečností.
                        </Grid>
                        <Grid item xs={12} className={classes.startupTextButtonWrapped}>
                            <MyPurpleButton
                                style={{
                                    paddingLeft: '30px',
                                    paddingRight: '30px',
                                }}
                                onClick={() => {
                                    setTimeout(() => {
                                        scroller.scrollTo('app', {
                                            duration: 1000,
                                            delay: 0,
                                            smooth: 'easeInOutQuart',
                                        });
                                    }, 500);
                                }}

                            >
                                Chci aplikaci
                            </MyPurpleButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.startupImageWrapped}
                        xs={12}
                        md={5}
                    >
                        <img src={'/images/aplikace/banner_obrazek.png'} style={{maxWidth: '100%'}}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.searchRoot} id={'app'}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                    // className={classes.imageBackground}
                >
                    <Grid item xs={12} className={classes.searchTitle}>
                        Chci aplikaci
                    </Grid>
                    <Grid item xs={12} className={classes.searchTitleTwo}>
                        Napiš nám vzkaz.
                    </Grid>
                    <Grid item xs={12} className={classes.searchDescription}>
                        Ozveme se ti hned po přečtení.
                    </Grid>
                    <Grid item xs={12} className={classes.searchForm}>
                        <WantApplicationForm onSubmit={handleSubmit.bind(this)}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <Footer/>
    </Page>;
}

const HomePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        carouselItem: {

        },
        iconsWrapped: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '60px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        iconsScroll: {
            height: '110px',
            overflow: 'auto',
        },
        icon: {
            // verticalAlign: 'top',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        // root: {
        //     paddingTop: theme.spacing(10),
        //     height: '510px',
        //     fontFamily: 'century-gothic',
        //     backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
        //     color: colors.common.white,
        //     position: 'relative',
        //     textAlign: 'center',
        //     overflow: 'hidden',
        //
        // },

        root: {
            paddingTop: theme.spacing(5),
            height: '485px',
            fontFamily: 'century-gothic',
            backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
            color: colors.common.white,
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
        },
        rootTextTitle: {
            fontWeight: 'bold',
            fontSize: 42,
            zIndex: 2,
        },
        rootTextTitleTwo: {
            paddingTop: '10px',
            fontWeight: 'bold',
            fontSize: 33,
            zIndex: 2,
        },
        rootTextDescription: {
            marginTop: theme.spacing(3),
            fontSize: 18,
            zIndex: 2,
        },
        gridRoot: {
            'fontFamily': 'century-gothic',
            'height': '100%',
            'position': 'relative',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/aplikace/obrazek_mobil.png)',
                position: 'absolute',
                left: 0,
                right: '-500px',
                top: '100px',
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                },
            },
        },
        divider: {
            height: '13px',
            backgroundColor: colors.common.white,
            width: '100%',
            position: 'relative',
        },
        dividerWave: {
            bottom: 7,
            zIndex: 2,
            width: '100%',
            maxWidth: '293px',
            position: 'absolute',
            right: 0,
            left: 0,
            margin: 'auto',
        },
        dividerArrow: {
            position: 'absolute',
            maxHeight: '50%',
            right: 0,
            top: 22,
            left: 0,
            margin: 'auto',
        },
        searchWorkerRoot: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            fontFamily: 'century-gothic',
            minHeight: '265px',
            width: '100%',
            backgroundColor: colors.common.white,
        },
        searchTextWorkerGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            height: '100%',
            position: 'relative',
            textAlign: 'end',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        searchWorkerTitle: {
            textTransform: 'uppercase',
            paddingTop: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 21,
            zIndex: 2,
            color: '#343434',
        },
        searchWorkerDescription: {
            textAlign: 'justify',
            fontSize: 21,
            zIndex: 2,
            color: '#343434',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        startupRoot: {
            overflow: 'hidden',
            fontFamily: 'century-gothic',
            minHeight: '410px',
            width: '100%',
            backgroundColor: '#f2f2f2',
            paddingBottom: '100px',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '40px',
            },
        },
        startupText: {
            zIndex: 2,
            paddingTop: '180px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingTop: '50px',
            },
        },
        startupTextTitle: {
            fontWeight: 'bold',
            fontSize: 33,
            zIndex: 2,
            color: '#966dff',
            textTransform: 'uppercase',
        },
        startupTextDescription: {
            paddingTop: '30px',
            fontSize: 21,
            color: '#343434',
        },
        startupTextButtonWrapped: {
            paddingTop: '80px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '30px',
            },
        },
        startupImageWrapped: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            zIndex: 2,
            paddingTop: '90px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '30px',
            },
        },
        searchRoot: {
            paddingTop: '60px',
            textAlign: 'center',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '340px',
            width: '100%',
            backgroundColor: colors.common.white,
            paddingBottom: '90px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '80px',
                paddingLeft: '80px',
                paddingBottom: '50px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: '20px',
                paddingLeft: '20px',
                paddingBottom: '50px',
            },
        },
        searchBackGroundImage: {
            'position': 'relative',
            'zIndex': 2,
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/obrazek_email.png)',
                position: 'absolute',
                right: '120px',
                bottom: '80px',
            },
        },
        searchTitle: {
            'zIndex': 3,
            'textTransform': 'uppercase',
            'color': '#859afe',
            'fontSize': 42,
            'fontWeight': 'bold',
            'textAlign': 'center',
            'position': 'relative',
            '&::before' : {
                height: '3px',
                width: '105px',
                margin: 'auto',
                backgroundColor: '#dcdcdc',
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-10px',
            },
        },
        searchTitleTwo: {
            zIndex: 3,
            paddingTop: 30,
            color: '#343434',
            fontSize: 29,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        searchDescription: {
            zIndex: 3,
            paddingTop: '10px',
            fontSize: 21,
        },
        searchForm: {
            'zIndex': 2,
            'paddingTop': '60px',
            // [theme.breakpoints.down('sm')]: {
            //     paddingRight: '80px',
            //     paddingLeft: '80px',
            // },
            // [theme.breakpoints.down('xs')]: {
            //     paddingRight: '20px',
            //     paddingLeft: '20px',
            // },
            'position': 'relative',
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/obrazek_email.png)',
                position: 'absolute',
                right: '120px',
                bottom: 0,
                [theme.breakpoints.down('sm')]: {
                    content: 'unset',
                },
            },
        },
        teamRootOne: {
            paddingTop: '60px',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '560px',
            width: '100%',
            backgroundColor: '#f2f2f2',
            paddingBottom: '30px',
        },
        teamRootTwo: {
            paddingTop: '60px',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '560px',
            width: '100%',
            backgroundColor: colors.common.white,
            paddingBottom: '30px',
        },
        teamImage: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        anet: {
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap-reverse',
            },
        },
        teamText: {
            paddingTop: '50px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        teamTextTitle: {
            fontWeight: 'bold',
            fontSize: 25,
        },
        teamTextShortDescription: {
            paddingTop: '10px',
        },
        teamTextDescription: {
            paddingTop: '30px',
        },
        teamTextEmailWrapped: {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            display: 'flex',
            paddingTop: '60px',
            lineHeight: '60px',
        },
        teamTextEmailText: {
            paddingLeft: '15px',
            fontWeight: 'bold',
            fontSize: '20',
            [theme.breakpoints.down('sm')]: {
                fontSize: '3vw',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '5vw',
            },
        },
    }),
);

export default AppsPage;
