import {Validator} from 'redux-form/lib/Field';
import isURL from 'validator/es/lib/isURL';

export const required: Validator = value => value ? undefined : 'Povinný';

export const isUrl: Validator = value => {
    return value && !isURL(value) ? 'Musí být url' : undefined;
};

export const email: Validator = (value) => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Špatně zadaná emailová adresa' : undefined;
};

export const maxLength: Validator = max => value =>
    value && value.length > max ? `Maximálně ${max} znaků` : undefined;

export const isNumber: Validator = value => value && isNaN(Number(value)) ? 'Musí být číslo' : undefined;

export const minLength: Validator = min => value =>
    value && value.length < min ? `Minimálně ${min} znaků` : undefined;

export const passwordsMatch = passwordField => (value, allValues) =>
    value !== allValues[passwordField] ? 'Hesla se musí shodovat!' : undefined;
