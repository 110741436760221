import {Button, colors, withStyles} from '@material-ui/core';

const MyBlueButton = withStyles({
    root: {
        '&:hover': {
            backgroundColor: '#859afe',
        },
        minWidth: '150px',
        lineHeight: '42px',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 'unset',
        backgroundColor: '#859afe',
        fontSize: 'unset',
        fontFamily: 'century-gothic',
        fontWeight: 'bold',
    },
    label: {
        fontSize: 20,
        color: colors.common.white,
        textTransform: 'lowercase',
    },
})(Button);

export default MyBlueButton;
