import {Button, colors, withStyles} from '@material-ui/core';

const MyPurpleDarkButton = withStyles({
    root: {
        '&:hover': {
            backgroundColor: '#7f3f98',
        },
        lineHeight: '57px',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 'unset',
        backgroundColor: '#7f3f98',
        fontSize: 'unset',
        fontFamily: 'century-gothic',
        fontWeight: 'bold',
    },
    label: {
        fontSize: 21,
        color: colors.common.white,
        textTransform: 'uppercase',
    },
})(Button);

export default MyPurpleDarkButton;
