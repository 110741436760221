import {Action} from "redux-actions";

export interface IMenuChangeState extends Action<{fixed: boolean}> {
    type: 'MENU_CHANGE';
}
export function changeMenuState({fixed}: {fixed: boolean}): IMenuChangeState {
    return {
        type: 'MENU_CHANGE', payload: {
            fixed,
        },
    };
}
