import React from 'react';
import BaseFormComponent, {Field, IButton} from '../../../core/components/form/BaseForm';
import {change, Field as FormField, reduxForm} from 'redux-form';
import {connect, useDispatch} from 'react-redux';
import {GetTextInputType} from '../../../core/components/form/components/TextInput';
import {email, required} from '../../../core/components/form/validation/baseValidation';
import {makeStyles} from '@material-ui/core/styles';
import {colors, Grid, GridProps} from '@material-ui/core';
import MyBlueButton from '../../../core/themes/components/MyBlueButton';
import {GetCaptchaType} from '../../../core/components/form/components/Captcha';
import {GetFileInput} from '../../../core/components/form/components/FileInput';

class CarrierForm extends BaseFormComponent<CarrierFormData> {

    protected async onSubmit(data: CarrierFormData) {
        const {onSubmit} = this.props;
        const blob = await fetch(data.attachments.url).then(r => r.blob());
        const file = new File([blob], data.attachments.name);
        onSubmit({...data, attachments: file});
        // @ts-ignore
        this.props.dispatch(change('CarrierForm', 'captcha', '', false, false));

        // form: string, field: string, value: any, touch?: boolean, persistentSubmitErrors?: boolean

    }

    protected getContainerProps(): GridProps {
        return {
            // container: false,
            // xs: 6,
        };
    }

    protected getFields(): Field[] {

        const fields: Field[] = [
            {
                CustomRender: () => <CustomInput/>,
            },
        ];

        return fields;
    }

    protected getButtonConfig(): IButton {
        return {
            CustomRender: () => <CustomButton/>,
        };
    }
}

const CustomInput = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            paddingTop: '40px',
            paddingRight: '40px',
            paddingLeft: '40px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '30px',
                paddingRight: 0,
                paddingLeft: 0,
            },
        },
        labelClass: {
            fontFamily: 'century-gothic',
            fontSize: 16,
            top: '-50px',
            left: '-15px',
        },
        labelSelectClass: {
            fontFamily: 'century-gothic',
            fontSize: 16,
            top: '-50px',
            left: 0,
        },
        inputRoot: {
            zIndex: 6,
            borderRadius: 'unset',
            backgroundColor: colors.common.white,
            // zIndex: 5,

            // 'label[data-shrink=false]': {
            //     opacity: '0.5 !important',
            // },

        },
    }));
    const classes = useStyles({});

    const typeComponentEmail = GetTextInputType(
        'email',
        {
            type: 'email',
            label: 'E-mail',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
            email,
        ],
    );

    const typeComponentName = GetTextInputType(
        'name',
        {
            label: 'Jméno a příjmení',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentPhone = GetTextInputType(
        'phone',
        {
            type: 'tel',
            label: 'Telefon',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentAttachment = GetFileInput(
        'attachments',
        {
            label: 'Příloha cv/portfolio (pdf, doc, docx)',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const typeComponentDescription = GetTextInputType(
        'description',
        {
            multiline: true,
            rows: 5,
            rowsMax: 5,
            type: 'textarea',
            label: 'Pár slov o tobě',
            variant: 'outlined',
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                },
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                classes: {
                    formControl: classes.labelClass,
                },
            },
        },
        {},
        [
            required,
        ],
    );

    const dispatch = useDispatch();
    dispatch({
        type: 'REGISTER_CAPTCHA',
        payload: {
            formName: 'CarrierForm',
        },
    });

    const typeCaptcha = GetCaptchaType(
            'captcha',
            {
                xs: 12,
            },
            [
                required,
            ],
        );

    //
    // // @ts-ignore
    // if (this.props.captcha.countSend >= config.reCaptcha.countTry) {
    //     fields = [
    //         ...fields,
    //         {
    //             typeComponent: GetCaptchaType(
    //                 'captcha',
    //                 {
    //                     xs: 12,
    //                 },
    //                 [
    //                     required,
    //                 ],
    //             ),
    //         },
    //     ];
    // }

    return (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeComponentName.validate}
                    {...typeComponentName}
                    component={typeComponentName.component}
                    name={typeComponentName.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeComponentEmail.validate}
                    {...typeComponentEmail}
                    component={typeComponentEmail.component}
                    name={typeComponentEmail.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeComponentPhone.validate}
                    {...typeComponentPhone}
                    component={typeComponentPhone.component}
                    name={typeComponentPhone.name}
                />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeComponentAttachment.validate}
                    {...typeComponentAttachment}
                    component={typeComponentAttachment.component}
                    name={typeComponentAttachment.name}
                />
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeComponentDescription.validate}
                    {...typeComponentDescription}
                    component={typeComponentDescription.component}
                    name={typeComponentDescription.name}
                />
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <FormField
                    formName={'CarrierForm'}
                    validate={typeCaptcha.validate}
                    {...typeCaptcha}
                    component={typeCaptcha.component}
                    name={typeCaptcha.name}
                />
            </Grid>
        </React.Fragment>
    );
};

const CustomButton = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '32px',
            paddingBottom: '8px',
            zIndex: 6,
        },
        label: {
            textTransform: 'uppercase',
        },
        button: {
            paddingTop: '8px',
            paddingBottom: '8px',
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }));
    const classes = useStyles({});
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grid}>
                <MyBlueButton
                    style={{height: '100%'}}
                    classes={{
                        label: classes.label,
                        root: classes.button,
                    }}
                    fullWidth
                    // className={classes.saveButton}
                    type='submit'
                    variant='contained'
                >
                    ODESLAT
                </MyBlueButton>
            </Grid>
        </React.Fragment>
    );
};

export interface CarrierFormData {
    name: string;
    email: string;
    phone: string;
    attachments: any;
    description: string;
    captcha: string;
}

export default connect(null)(
    reduxForm({
        enableReinitialize : true,
        form: 'CarrierForm',
    })(CarrierForm),
);
