import React, {Component} from 'react';
import {Field as FormField, InjectedFormProps} from 'redux-form';
import {Button, Grid, GridProps, GridTypeMap, PropTypes} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {
    GridContentAlignment,
    GridDirection,
    GridItemsAlignment,
    GridJustification,
    GridSize,
    GridSpacing,
    GridWrap,
} from '@material-ui/core/Grid/Grid';
import {Validator} from 'redux-form/lib/Field';

// tslint:disable-next-line:max-line-length
export default abstract class BaseFormComponent<FormData, P extends BaseFormComponentType<FormData> = BaseFormComponentType<FormData>, S = {}> extends Component<P & InjectedFormProps<{}, P>, S> {

    protected getFields(): Field[] {
        return [];
    }
    protected getButtonConfig(): IButton {
        return {};
    }

    protected onSubmit(data: FormData) {
        const {onSubmit} = this.props;
        onSubmit(data);
    }

    protected getContainerProps(): GridProps {
        return {};
    }

    protected getInputsWrapped(children) {
        return <React.Fragment>
            {children}
        </React.Fragment>;
    }

    render() {
        const fields = this.getFields();
        const button = this.getButtonConfig();

        const {handleSubmit} = this.props;

        const renderButton = () => {
            const {buttonProps, CustomRender} = button;
            if (CustomRender) {
                return CustomRender({});
            }
            if (buttonProps) {
                const {buttonSizing, buttonSettings} = buttonProps;
                const {title} = buttonSettings;
                return (
                    <Grid item xs={12} {...buttonSizing}>
                        <Button
                            fullWidth
                            type={'submit'}
                            color={'primary'}
                            variant={'contained'}
                            {...buttonSettings}
                        >
                            {title ? title : 'Submit'}
                        </Button>
                    </Grid>
                );
            }
            return null;
        };

        const renderInputs = () => {
            return (
                <Grid container {...this.getContainerProps()}>
                    {fields.map((item, key) => {
                        const {typeComponent, CustomRender} = item;
                        if (CustomRender) {
                            return <React.Fragment key={key}>
                                {CustomRender({})}
                            </React.Fragment>;
                        }
                        const {name, component, sizing, validate} = typeComponent;
                        return(
                            <Grid key={key} item xs={12} {...sizing}>
                                <FormField
                                    formName={this.props.form}
                                    validate={validate}
                                    {...typeComponent}
                                    component={component}
                                    name={name}
                                />
                            </Grid>
                        );
                    })}
                    {renderButton()}
                </Grid>
            );
        };

        return(
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                {this.getInputsWrapped(renderInputs())}
            </form>
        );
    }
}

export interface SizingInterface extends Partial<Record<Breakpoint, boolean | GridSize>> {
    alignContent?: GridContentAlignment;
    alignItems?: GridItemsAlignment;
    container?: boolean;
    direction?: GridDirection;
    item?: boolean;
    justify?: GridJustification;
    spacing?: GridSpacing;
    wrap?: GridWrap;
    zeroMinWidth?: boolean;
}

export interface InputType {
    name: string;
    component: React.FC;
    sizing?: SizingInterface;
    validate?: Validator[];
}

export interface Field {
    typeComponent?: InputType;
    CustomRender?: React.FunctionComponent<any>;
}

export interface IButton {
    buttonProps?: ButtonInterface;
    CustomRender?: React.FunctionComponent<any>;
}

export interface ButtonInterface {
    buttonSizing: SizingInterface;
    buttonSettings?: {
        title?: string;
        color?: PropTypes.Color;
        disableElevation?: boolean;
        disableFocusRipple?: boolean;
        endIcon?: React.ReactNode;
        fullWidth?: boolean;
        href?: string;
        size?: 'small' | 'medium' | 'large';
        startIcon?: React.ReactNode;
        variant?: 'text' | 'outlined' | 'contained';
    };
}

export interface BaseFormComponentType<FormData> {
    onSubmit: (data: FormData) => void;
}
