import {OptionsObject, SnackbarMessage, VariantType} from 'notistack';

class Notify {

    public snackBar: (message: SnackbarMessage, options?: OptionsObject) => OptionsObject['key'] | null;

    public setSnackBar(snackBar: (message: SnackbarMessage, options?: OptionsObject) => OptionsObject['key'] | null) {
        this.snackBar = snackBar;
    }

    public createNotification(message: string, options?: OptionsObject) {
        this.snackBar(message, options);
    }
}

export const Notification = new Notify();
