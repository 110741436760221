import React from 'react';
import {TextField, TextFieldProps} from '@material-ui/core';
import {InputType, SizingInterface} from '../BaseForm';
import {Validator} from 'redux-form/lib/Field';

export interface TextInputType extends InputType {
    textFieldProps?: TextFieldProps;
}

export function GetTextInputType(name: string, textFieldProps?: TextFieldProps, sizing?: SizingInterface, validate?: Validator[]): TextInputType {
    return {
        name,
        sizing,
        textFieldProps,
        validate,
        component: TextInput,
    };
}

const TextInput: React.FC = (field: any | TextInputType) => {
    const {textFieldProps} = field;
    return (
        <TextField
            className={'myTextField'}
            variant='outlined'
            margin='normal'
            fullWidth
            error={field.meta.touched && field.meta.error ? true : false}
            helperText={field.meta.touched && field.meta.error}
            {...textFieldProps}
            {...field.input}
            onChange={(event) => {
                field.input.onChange(event)
                if (textFieldProps.onChange) {
                    textFieldProps.onChange(event);
                }
            }}
            onBlur={(event) => {
                const {relatedTarget} = event;
                // @ts-ignore
                if (relatedTarget && ('button' === relatedTarget.getAttribute('type'))) {
                    event.preventDefault();
                } else {
                    field.input.onBlur(event);
                }

            }}
        />
    );
};

export default TextInput;
