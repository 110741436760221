import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery, colors
} from '@material-ui/core';
import Page from "../../core/components/Page";
import MyBlueButton from "../../core/themes/components/MyBlueButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    // padding: theme.spacing(3),
    paddingTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    // width: 560,
    // maxHeight: 300,
    // height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  text: {
    fontFamily: 'century-gothic',
    fontWeight: 'bold',
    fontSize: 62,
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      // paddingTop: '40px',
      // paddingBottom: '40px',
      lineHeight: 'unset',
      fontSize: 42,
    },
  },
  button: {
    lineHeight: '60px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  label: {
    fontSize: 20,
    color: colors.common.white,
    textTransform: 'uppercase',
  },
}));

function Error404() {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      className={classes.root}
      title="Error 404"
    >
      <Typography
        className={classes.text}
        align="center"
        variant={mobileDevice ? 'h4' : 'h1'}
      >
        Oops!
      </Typography>
      <Typography
        className={classes.text}
        align="center"
        variant={mobileDevice ? 'h4' : 'h1'}
      >
        STRÁNKA NENALEZENA
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt="Under development"
          className={classes.image}
          src="/images/404.png"
        />
      </div>
      <div className={classes.buttonContainer}>
        <MyBlueButton
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          // color="primary"
          component={RouterLink}
          to="/"
          // variant="outlined"
        >
          ZPĚT NA STRÁNKU
        </MyBlueButton>
      </div>
    </Page>
  );
}

export default Error404;
