import palette from '../palette';

export default {
  root: {
    // 'label[data-shrink=false] + & ::-webkit-input-placeholder': {
    //   opacity: '1 !important',
    // },
  },
  input: {
    // 'label[data-shrink=false]': {
    //   placeholder: { opacity: 0.5 }
    // },


    '&::placeholder': {
      'fontFamily': 'century-gothic',
      fontSize: 16
      // color: 'red !important',
     },
  },
};
