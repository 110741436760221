import {createMuiTheme} from '@material-ui/core/styles';
import {colors} from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const DefaultTheme = createMuiTheme({
    palette,
    // @ts-ignore
    typography,
    overrides,
});

// Object.assign(DefaultTheme, {
//     overrides: {
//         inputRoot: {
//             'label[data-shrink=false] + & ::-webkit-input-placeholder': {
//                 // opacity: '0.5 !important',
//             },
//         }
//         // MuiButton: {
//         //
//         // },
//         // inputRoot: {
//         //     'label[data-shrink=false] + & ::-webkit-input-placeholder': {
//         //         opacity: '0.5 !important',
//         //     },
//         // }
//     },
// });

// DefaultTheme = responsiveFontSizes(DefaultTheme);

export default DefaultTheme;
