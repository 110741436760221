import React, {Suspense} from 'react';
import {LinearProgress, makeStyles} from '@material-ui/core';
import {renderRoutes} from 'react-router-config';
import TopBar from "./TopBar";

const FrontPageContainer = (props) => {
    const classes = useStyles();

    return (
        <>
            <TopBar/>
            <div className={classes.container}>
                <div className={classes.content}>
                    <Suspense fallback={<LinearProgress />}>
                        {renderRoutes(props.route.routes)}
                    </Suspense>
                </div>
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        'minHeight': '100vh',
        'display': 'flex',
        '@media all and (-ms-high-contrast:none)': {
            height: 0, // IE11 fix
        },
    },
    content: {
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
    },
}));

export default FrontPageContainer;
