import React from 'react';
import {Router, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';
import DefaultTheme from '../bundles/core/themes/DefaultTheme';
import {renderRoutes} from 'react-router-config';
import {createBrowserHistory} from 'history';
import routes from './Routes';
const history = createBrowserHistory();


const AppRouter = ({store}) => (
    <Provider store={store}>
        <ThemeProvider theme={DefaultTheme}>
            <Router history={history}>
                <Switch>
                    {renderRoutes(routes)}
                </Switch>
            </Router>
        </ThemeProvider>
    </Provider>
);

export default AppRouter;
