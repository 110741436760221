export const ConfigLocalStorageKey = 'config';

export interface IConfig {
  [key: string]: Record<string, string|boolean|string[]|any>|string|boolean|number|any;
}

export const getConfig = (): IConfig => {
  const itemStr = localStorage.getItem(ConfigLocalStorageKey);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr, (key, value) => {
    if (value === 'true' || value === 'false' || typeof value === 'boolean') {
      return value === 'true' || value === true;
    }
    if (Array.isArray(value)) {
      return value;
    }
    if (!isNaN(value)) {
      return Number(value);
    }
    return value;
  });
  return item.value;
};

export const setConfig = async (): Promise<void> => {
  const config = await loadFile('/config.json');
  const now = new Date();

  const item = {
    value: config,
    expiry: now.getTime() + (3600 * 1000),
  };

  localStorage.setItem(ConfigLocalStorageKey, JSON.stringify(item));
};

const loadFile = async (filePath: string): Promise<IConfig> => {
  return await fetch(filePath)
    .then(async (response) => {
      return await response.json();
    }).catch(e => {
      console.error('Download config file failed', filePath, e.message);
    })
    .then((json) => {
      return json;
    });
};
