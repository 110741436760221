export default class HttpApiCallError extends Error {

  public statusCode = undefined;
  public message = undefined;
  public stack = undefined;
  public name = undefined;
  public response = undefined;

  constructor(message, statusCode) {
    super(message);
    this.message = message;
    this.statusCode = statusCode;
    this.stack = (new Error(message)).stack;
    this.name = 'HttpApiCallError';
  }

}

export interface IHttpApiCallError {
  message: string;
  response: any;
  statusCode: number;
}
