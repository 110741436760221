import {colors} from "@material-ui/core";

export default {
  root: {
    backgroundColor: colors.common.white,
  },
  input: {
    padding: '27px 13px 17px',
  },
  underline: {
    '&::after': {
      borderBottom: 'unset',
    },
  },
};
