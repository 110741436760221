import React, {useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {change, WrappedFieldProps} from 'redux-form';
import {createStyles, FormControl, FormHelperText, makeStyles, Theme} from '@material-ui/core';
import {InputType, SizingInterface} from '../BaseForm';
import {Validator} from 'redux-form/lib/Field';
import { getConfig } from '../../../../config';

export function GetCaptchaType(name: string, sizing?: SizingInterface, validate?: Validator[]): InputType {
    return {
        // @ts-ignore
        component: Captcha,
        name,
        sizing,
        validate,
    };
}

const Captcha = (props: WrappedFieldProps & {formName: string}) => {
    const config = getConfig();
    const ref = useRef();

    const {
        meta: { error, touched, dispatch, submitting },
        input: {name},
        formName,
    } = props;

    if (submitting) {
        setTimeout(() => {
            if (ref && ref.current) {
                // @ts-ignore
                ref.current.reset();
            }
        }, 500);
    }

    const onChange = (value) => {
        dispatch(change(formName, name, value));
    };

    const classes = useStyles({});
    return (
        <FormControl className={classes.formControl} error={!!(touched && error)}>
            <ReCAPTCHA
                ref={ref}
                sitekey={config?.reCaptcha?.siteKey}
                onChange={response => onChange(response)}
            />
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            alignItems: 'center',
            // margin: theme.spacing(1),
            width: '100%',
        },
    }),
);

export default Captcha;
