import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.less';
import './assets/styles/scss/main.scss';
import {register, unregister} from './serviceWorker';
import AppRouter from './router/AppRouter';
import configureStore from './bundles/core/store/configureStore';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {Notification} from './bundles/core/components/notification/Notify';
import { setConfig } from './bundles/config';
const store = configureStore();
const prod = process.env.NODE_ENV === 'production' ? true : false;

const App = () => {
    const {enqueueSnackbar} = useSnackbar();
    Notification.setSnackBar(enqueueSnackbar);
    return <AppRouter store={store}/>;
};

const render = async () => {
    await setConfig();
    ReactDOM.render(
        <SnackbarProvider >
            <App/>
        </SnackbarProvider>,
        document.getElementById('root'),
    );
};

render();

if (module.hot) {
    module.hot.accept('./router/AppRouter', () => {
        render();
    });
}
if (prod) {
    unregister();
} else {
    register();
}
