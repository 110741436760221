import React from 'react';
import BaseFormComponent, {Field, IButton} from '../../../core/components/form/BaseForm';
import {Field as FormField, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {GetTextInputType} from '../../../core/components/form/components/TextInput';
import {email, required} from '../../../core/components/form/validation/baseValidation';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, GridProps} from '@material-ui/core';
import MyPurpleDarkButton from '../../../core/themes/components/MyPurpleDarkButton';

class CooperationForm extends BaseFormComponent<CooperationFormData> {

    protected getContainerProps(): GridProps {
        return {
            // container: false,
            // xs: 6,
        };
    }

    protected getFields(): Field[] {

        const fields: Field[] = [
            {
                CustomRender: () => <CustomInput/>,
            },
        ];

        return fields;
    }

    protected getButtonConfig(): IButton {
        return {
            CustomRender: () => <CustomButton/>,
        };
    }
}

const CustomInput = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            paddingRight: '15px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
            },
        },
        inputRoot: {
            backgroundColor: '#ffff',
            '&:hover': {
                backgroundColor: '#ffff',
            },
            '&:focus': {
                backgroundColor: '#ffff',
            },
            transition: 'unset',
            // paddingLeft: '10px',
            // paddingTop: '12px',
            padding: '24px 15px 17px',
            'borderRadius': 'unset',
            fontFamily: 'century-gothic',
            fontSize: 20,
            '&::placeholder': {
                fontFamily: 'century-gothic',
                fontSize: 20,
                color: '#343434 !important',
                opacity: 1,
            },
        },
        inputWrapped: {
            '& .MuiFormHelperText-root': {
                color: 'white !important',
            },
        },
        labelClass: {
            fontFamily: 'century-gothic',
            fontSize: 16,
            top: '-50px',
            left: '-15px',
            backgroundColor: '#ffff',
        },
        formControl: {
            backgroundColor: '#ffff',
        }
    }));
    const classes = useStyles({});

    const typeComponent = GetTextInputType(
        'email',
        {
            type: 'email',
            className: classes.inputWrapped,
            // label: 'e-mail',
            variant: 'filled',
            placeholder: 'e-mail',
            InputProps: {
                classes: {
                    // root: classes.inputWrapped,
                    // formControl: classes.inputWrapped,
                },
            },
            inputProps: {
                className: classes.inputRoot,
            },
            InputLabelProps: {
                disableAnimation: true,
                shrink: false,
                placeholder: 'kus',
                classes: {
                    formControl: classes.labelClass,
                    root: classes.formControl,
                },
            },
        },
        {
            xs: 6,
        },
        [
            required,
            email,
        ],
    );

    const {name, component, sizing, validate} = typeComponent;
    return (
        <Grid item xs={12} md={6} className={classes.grid}>
            <FormField
                formName={'CooperationForm'}
                validate={validate}
                {...typeComponent}
                component={component}
                name={name}
            />
        </Grid>
    );
};

const CustomButton = () => {
    const useStyles = makeStyles((theme) => ({
        grid: {
            paddingLeft: '15px',
            paddingTop: '16px',
            paddingBottom: '8px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
            },
        },
    }));
    const classes = useStyles({});
    return (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.grid}>
                <MyPurpleDarkButton
                    style={{height: '100%'}}
                    fullWidth
                    // className={classes.saveButton}
                    type='submit'
                    variant='contained'
                >
                    Odeslat
                </MyPurpleDarkButton>
            </Grid>
        </React.Fragment>
    );
};

export interface CooperationFormData {
    email: string;
}

export default connect(null)(
    reduxForm({
        enableReinitialize : true,
        form: 'CooperationForm',
    })(CooperationForm),
);
