import React, {useEffect, useState} from 'react';
import {AppBar, Link, Theme, Toolbar, makeStyles, Container, Grid, colors, Hidden, IconButton} from '@material-ui/core';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';
import {Menu} from '@material-ui/icons';
import BaseDrawer from '../../components/drawler/BaseDrawler';
import {useSelector} from 'react-redux';
import {IAppState} from '../../reducers';
import clsx from 'clsx';
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const TopBar = () => {
    const classes = TopBarStyles();
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [animation, setAnimation] = useState(false);
    const menu = useSelector((state: IAppState) => state.menu);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (menu.fixed) {
            setTimeout(() => {
                setAnimation(true);
            }, 100);
        } else {
            setAnimation(false);
        }
    }, [menu]);

    const goToTop = () => {
        setTimeout(() => {
            scroll.scrollToTop();
        }, 100);
    };

    return (
        <React.Fragment>
            <AppBar
                className={clsx({
                    [classes.rootFixed]: menu.fixed,
                    [classes.rootAnimated]: animation,
                    [classes.root]: true,
                })}
            >
                <Container maxWidth={'lg'}>
                    <Toolbar style={{padding: 0}}>
                        <div className={classes.logo}>
                            <Link
                                className={clsx(classes.link, {
                                })}
                                onClick={(e) => {
                                    goToTop();
                                }}
                                component={RouterLink}
                                to='/'
                                variant='h5'
                                underline='none'
                            >
                                <img className={classes.image} src={'/images/home/logo.png'}/>
                            </Link>

                        </div>
                        <div className={classes.flexGrow} />
                        <Hidden mdUp>
                            <IconButton
                                className={classes.menuButton}
                                color='inherit'
                                onClick={() => {setOpenMobileMenu(true); }}
                            >
                                <Menu />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            <div className={classes.linkWrapped}>
                                <Link
                                    onClick={(e) => {
                                        goToTop();
                                    }}
                                    className={clsx(classes.link, {
                                        [classes.linkActive]: location.pathname === '/about-us',
                                    })}
                                    component={RouterLink}
                                    to='/about-us'
                                    variant='h5'
                                    underline='none'
                                >
                                    Kdo jsme
                                </Link>
                            </div>
                            <div className={classes.linkWrapped}>
                                <Link
                                    onClick={(e) => {
                                        goToTop();
                                    }}
                                    className={clsx(classes.link, {
                                        [classes.linkActive]: location.pathname === '/apps',
                                    })}
                                    component={RouterLink}
                                    to='/apps'
                                    variant='h5'
                                    underline='none'
                                >
                                    Aplikace
                                </Link>
                            </div>
                            <div className={classes.linkWrapped}>
                                <Link
                                    className={clsx(classes.link, {
                                        [classes.linkActive]: location.pathname + location.search === '/?contact=true',
                                    })}
                                    component={RouterLink}
                                    onClick={(e) => {
                                        setTimeout(() => {
                                            scroller.scrollTo('contact', {
                                                duration: 1000,
                                                delay: 0,
                                                smooth: 'easeInOutQuart',
                                            });
                                        }, 500);
                                    }}
                                    to='/?contact=true'
                                    variant='h5'
                                    underline='none'
                                >
                                    Kontakt
                                </Link>
                            </div>
                            <div className={classes.linkWrapped}>
                                <Link
                                    onClick={(e) => {
                                        goToTop();
                                    }}
                                    className={clsx(classes.link, {
                                        [classes.linkActive]: location.pathname === '/career',
                                    })}
                                    component={RouterLink}
                                    to='/career'
                                    variant='h5'
                                    underline='none'
                                >
                                    Kariéra
                                </Link>
                            </div>
                        </Hidden>
                    </Toolbar>
                </Container>
            </AppBar>
            <BaseDrawer
                globalClickEvent={() => {setOpenMobileMenu(false); }}
                open={openMobileMenu}
                onClose={() => {setOpenMobileMenu(false); }}
                lists={[
                    {
                        // className: classes.companyWrapped,
                        items: [
                            {
                                action: () => {
                                    history.push('/about-us');
                                    goToTop();
                                },
                                isActive: () => {
                                    return location.pathname === '/about-us';
                                },
                                text: 'Kdo jsme',
                            },
                            {
                                action: () => {
                                    history.push('/apps');
                                    goToTop();
                                },
                                isActive: () => {
                                    return location.pathname === '/apps';
                                },
                                text: 'Aplikace',
                            },
                            {
                                action: () => {
                                    history.push('/?contact=true');
                                    setTimeout(() => {
                                        scroller.scrollTo('contact', {
                                            duration: 1000,
                                            delay: 0,
                                            smooth: 'easeInOutQuart',
                                        });
                                    }, 500);
                                },
                                isActive: () => {
                                    return location.pathname + location.search === '/?contact=true';
                                },
                                text: 'Kontakt',
                            },
                            {
                                action: () => {
                                    history.push('/career');
                                    goToTop();
                                },
                                isActive: () => {
                                    return location.pathname === '/career';
                                },
                                text: 'Kariéra',
                            },
                        ],
                    },

                ]}
            />
        </React.Fragment>

    );
};

export const TopBarStyles = makeStyles((theme: Theme) => ({
    root: {
        top: '20px',
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'century-gothic',
        backgroundColor: 'unset',
        boxShadow: 'unset',
        position: 'absolute',
    },
    rootFixed: {
        // transition: 'all .2s ease',
        top: '-64px',
        position: 'fixed !important' as 'fixed',
        backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
        marginTop: '0 !important' as '0',
    },
    rootAnimated: {
        transition: 'all .2s ease',
        top: 0,
    },
    settingMenu: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    logo: {
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px',
        },
    },
    image: {
        maxWidth: '100%',
    },
    linkWrapped: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    link: {
        color: colors.common.white,
        fontFamily: 'century-gothic',
        fontWeight: 'bold',
    },
    linkActive: {
        color: '#5f4bc6',
    },
    flexGrow: {
        flexGrow: 1,
    },
}));

export default TopBar;
