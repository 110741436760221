import React, {useEffect, useRef} from 'react';
import Page from '../../../core/components/Page';
import {colors, Container, Grid, Hidden, makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import MyPurpleButton from '../../../core/themes/components/MyPurpleButton';
import {useDispatch, useSelector} from 'react-redux';
import {changeMenuState} from '../../../core/actions/menu';
import {IAppState} from '../../../core/reducers';
import MyBlueButton from '../../../core/themes/components/MyBlueButton';
import {withRouter} from 'react-router-dom';
import Footer from '../../Footer';
import {animateScroll as scroll, scroller} from 'react-scroll';
let timeout;

const lat = 48.988172;
const lng = 14.472701;

function HomePage({history}) {

    const goToTop = () => {
        setTimeout(() => {
            scroll.scrollToTop();
        }, 100);
    };

    const classes = HomePageStyles({});
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const menu = useSelector((state: IAppState) => state.menu);
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            if (scrollRef.current) {
                const elementTopPosition = scrollRef.current.offsetTop;
                const windowScrollTopPosition = window.scrollY;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    if (windowScrollTopPosition >= elementTopPosition) {
                        if (menu.fixed === true) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: true,
                        }));
                    } else {
                        if (menu.fixed === false) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: false,
                        }));
                    }
                }, 5);
            }
        });
    }, [menu]);
    return <Page
        title={'Homepage'}
    >
        <div className={classes.root}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    className={classes.gridRoot}
                    container
                    direction='column'
                    justify='center'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.rootTextTitle}>
                        Systems
                    </Grid>
                    <Grid item className={classes.rootTextTitle}>
                        developer
                    </Grid>
                    <Grid item className={classes.rootTextDescription}>
                        Aplikace přesně podle tvého gusta
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.divider} ref={scrollRef}>
            <div className={classes.dividerWave}>
                <img src={'/images/home/vlnka.png'} style={{maxWidth: '100%'}}/>
                <img className={classes.dividerArrow} src={'/images/home/sipky.png'} onClick={() => {
                    scroller.scrollTo('arrowClick', {
                        duration: 1000,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                    });
                }}/>
            </div>
        </div>
        <div className={classes.searchWorkerRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid

                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid item xs={12} md={5} className={classes.searchImageWrapped}>
                        <img src={'/images/home/obrazek_programator.png'}  style={{maxWidth: '100%'}}/>
                    </Grid>
                    <Grid
                        className={classes.searchTextWorkerGrid}
                        item
                        xs={12}
                        md={7}
                        container
                        direction='column'
                        justify='flex-start'
                        alignItems='flex-end'
                    >
                        <Grid item className={classes.searchWorkerTitle}>
                            Hledáme programátora. Budeš to ty?
                        </Grid>
                        <Grid item className={classes.searchWorkerDescription}>
                            Přidej se k nám do týmu a dělej to, co tě baví.
                        </Grid>
                        <Grid item className={classes.searchWorkerButtonArea}>
                            <MyPurpleButton onClick={() => {
                                history.push('/career');
                                goToTop();
                            }}>
                                Chci do týmu
                            </MyPurpleButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.divider} />
        <div className={classes.ideaRoot} id={'arrowClick'}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        xs={12} md={6}
                        className={classes.ideaTextGrid}
                    >
                        <Grid item className={classes.ideaTextTitle}>
                            MÁŠ NÁPAD?<br/>
                            Vytvoříme ti vlastní aplikaci.
                        </Grid>
                        <Grid item className={classes.ideaTextDescription}>
                            Tvůj návrh projdeme a společně dáme dohromady to nejlepší řešení pro daný typ aplikace.
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.ideaImageWrapped}>
                        <Hidden smDown>
                            <img className={classes.ideaImage} src={'/images/home/DIPcom_home_page_03.png'} />
                        </Hidden>
                    </Grid>
                </Grid>

            </Container>
        </div>
        <div className={classes.resultRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        className={classes.resultTitle}
                        xs={12}
                    >
                        K výsledku v 6 krocích
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultOneWrapped}
                    >
                       <Grid item xs={12} md={6} className={classes.resultOneLeft}>
                           <img src={'/images/home/obrazek_napad.png'} style={{maxWidth: '100%'}}/>
                       </Grid>
                        <Grid item xs={12} md={6} className={classes.resultOneRight}>
                            <Hidden smDown>
                                <div className={classes.resultOneRightImage}>
                                    <div className={classes.resultOneRightImageText}>
                                        01
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultOneRightTitle}>
                                Nápad
                            </div>
                            <div className={classes.resultOneRightDescription}>
                                Máš už appku v hlavě nebo potřebuješ vyřešit nějaký problém se stávající aplikací? Jsme tu pro tebe.
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultTwoWrapped}
                    >
                        <Grid item xs={12} md={6} className={classes.resultTwoLeft}>
                            <Hidden smDown>
                                <div className={classes.resultTwoLeftImage}>
                                    <div className={classes.resultTwoLeftImageText}>
                                        02
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultTwoRightTitle}>
                                Analýza
                            </div>
                            <div className={classes.resultTwoRightDescription}>
                                Ze všeho nejdřív se spolu sejdeme a vytvoříme ti prvotní rozvržení aplikace. Pokud se bude líbit tobě i nám, vesele se pustíme do práce.
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.resultTwoRight}>
                            <img src={'/images/home/obrazek_analyza.png'} style={{maxWidth: '100%'}}/>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultOneWrapped}
                    >
                        <Grid item xs={12} md={6} className={classes.resultOneLeft}>
                            <img src={'/images/home/obrazek_vyvoj.png'} style={{maxWidth: '100%'}}/>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.resultOneRight}>
                            <Hidden smDown>
                                <div className={classes.resultOneRightImage}>
                                    <div className={classes.resultOneRightImageText}>
                                        03
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultOneRightTitle}>
                                Vývoj
                            </div>
                            <div className={classes.resultOneRightDescription}>
                                V této části procesu už máme teorii v malíku, teď přichází čas na praxi. Jde se programovat a kódovat!
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultTwoWrapped}
                    >
                        <Grid item xs={12} md={6} className={classes.resultTwoLeft}>
                            <Hidden smDown>
                                <div className={classes.resultTwoLeftImage}>
                                    <div className={classes.resultTwoLeftImageText}>
                                        04
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultTwoRightTitle}>
                                Testování
                            </div>
                            <div className={classes.resultTwoRightDescription}>
                                Aby se aplikace líbila nejen nám, ale především uživatelům, vše ladíme do posledních detailů. Proto nastává koloběh testování, jestli vše funguje, jak má.
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.resultTwoRight}>
                            <img src={'/images/home/obrazek_testovani.png'} style={{maxWidth: '100%'}}/>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultOneWrapped}
                    >
                        <Grid item xs={12} md={6} className={classes.resultOneLeft}>
                            <img src={'/images/home/obrazek_spusteni.png'} style={{maxWidth: '100%'}}/>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.resultOneRight}>
                            <Hidden smDown>
                                <div className={classes.resultOneRightImage}>
                                    <div className={classes.resultOneRightImageText}>
                                        05
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultOneRightTitle}>
                                Spuštění
                            </div>
                            <div className={classes.resultOneRightDescription}>
                                A je to! Hotová appka letí do světa. Ale neboj, tím to pro nás nekončí. Funkčnost kontrolujeme průběžně.
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultTwoWrapped}
                    >
                        <Grid item xs={12} md={6} className={classes.resultTwoLeft}>
                            <Hidden smDown>
                                <div className={classes.resultTwoLeftImage}>
                                    <div className={classes.resultTwoLeftImageText}>
                                        06
                                    </div>
                                </div>
                            </Hidden>
                            <div className={classes.resultTwoRightTitle}>
                                Marketing
                            </div>
                            <div className={classes.resultTwoRightDescription}>
                                Starosti s marketingem nech také na nás. Postaráme se, aby uživatelé aplikaci snadno našli a zaujala je.
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.resultTwoRight}>
                            <img src={'/images/home/obrazek_marketing.png'} style={{maxWidth: '100%'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.contactRoot} id={'contact'}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        className={classes.contactTitle}
                        xs={12}
                    >
                        Kontakt
                    </Grid>
                    <Grid
                        item
                        className={classes.contactTitleTwo}
                        xs={12}
                    >
                        Pojď si s námi popovídat
                    </Grid>
                    <Grid
                        item
                        className={classes.contactDescription}
                        xs={12}
                    >
                        o tvém projektu.<br/>
                        Přijď, volej, mailuj nebo pošli poštovního holuba.
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.contactIconsRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                    className={classes.contactIconGrid}
                >
                    <Grid item className={classes.contactIconGridItem}
                          xs={12}
                          md={4}
                          container
                          direction='column'
                          justify='flex-start'
                          alignItems='center'
                    >
                        <div className={classes.contactIconBubble}>
                            <img src={'/images/home/iconka_mapa.png'} style={{maxWidth: '100%'}}/>
                        </div>
                        <div className={classes.contactIconTitle}>
                            Kde nás najdeš
                        </div>
                        <Grid
                            container
                            direction='column'
                            justify='space-between'
                            alignItems='center'
                            className={classes.contactIconBottomArea}
                        >
                            <div className={classes.contactIconDescription}>
                                Kněžskodvorská 2296/8A <br/>
                                České Budějovice <br/>
                                370 04
                            </div>
                            <div className={classes.contactIconButton}>
                                <MyBlueButton
                                    onClick={() => {

                                        if ( (navigator.platform.indexOf('iPhone') !== -1)
                                            || (navigator.platform.indexOf('iPod') !== -1)
                                            || (navigator.platform.indexOf('iPad') !== -1)) {
                                            window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`);
                                        } else {
                                            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`);
                                        }

                                    }}
                                >
                                    navigovat
                                </MyBlueButton>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.contactIconGridItem}
                          xs={12}
                          md={4}
                          container
                          direction='column'
                          justify='flex-start'
                          alignItems='center'
                    >
                        <div className={classes.contactIconBubble}>
                            <img src={'/images/home/iconka_telefon.png'} style={{maxWidth: '100%'}}/>
                        </div>
                        <div className={classes.contactIconTitle}>
                            Zavolej nám
                        </div>
                        <Grid
                            container
                            direction='column'
                            justify='space-between'
                            alignItems='center'
                            className={classes.contactIconBottomArea}
                        >
                            <div className={classes.contactIconDescription}>
                                +420 720 208 138
                            </div>
                            <div className={classes.contactIconButton}>
                                <MyBlueButton
                                    onClick={() => {
                                        window.open('tel:+420720208138');
                                    }}
                                >
                                    zavolat
                                </MyBlueButton>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.contactIconGridItem}
                          xs={12}
                          md={4}
                          container
                          direction='column'
                          justify='flex-start'
                          alignItems='center'
                    >
                        <div className={classes.contactIconBubble}>
                            <img src={'/images/home/iconka_tuzka.png'} style={{maxWidth: '100%'}}/>
                        </div>
                        <Grid item className={classes.contactIconTitle}>
                            Napiš nám
                        </Grid>
                        <Grid
                            container
                            direction='column'
                            justify='space-between'
                            alignItems='center'
                            className={classes.contactIconBottomArea}
                        >
                            <div className={classes.contactIconDescription}>
                                info@dipcom.cz
                            </div>
                            <div className={classes.contactIconButton}>
                                <MyBlueButton
                                    onClick={() => {
                                        window.open('mailto:info@dipcom.cz');
                                    }}
                                >
                                    napsat
                                </MyBlueButton>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2618.1779031063274!2d14.470565715817505!3d48.988171799513644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47734e2e9fce702f%3A0x9e5f6ca1caea3dc4!2zS27Em8W-c2tvZHZvcnNrw6EgMjI5Ni84QSwgxIxlc2vDqSBCdWTEm2pvdmljZSAzLCAzNzAgMDQgxIxlc2vDqSBCdWTEm2pvdmljZQ!5e0!3m2!1scs!2scz!4v1591267482224!5m2!1scs!2scz'
            width='100%' height='600' frameBorder='0' aria-hidden='false'
        />
        <Footer/>
    </Page>;
}

const HomePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            height: '590px',
            fontFamily: 'century-gothic',
            backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
            color: colors.common.white,
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',

        },
        rootTextTitle: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 58,
            zIndex: 2,
            [theme.breakpoints.down('sm')]: {
                fontSize: 48,
            },
        },
        rootTextDescription: {
            marginTop: theme.spacing(3),
            fontSize: 21,
            zIndex: 2,
        },
        gridRoot: {
            'fontFamily': 'century-gothic',
            'height': '100%',
            'position': 'relative',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/home/D_slider.png)',
                position: 'absolute',
                right: 0,
                bottom: 0,
            },
        },
        divider: {
            height: '13px',
            backgroundColor: colors.common.white,
            width: '100%',
            position: 'relative',
        },
        dividerWave: {
            bottom: 7,
            zIndex: 2,
            width: '100%',
            maxWidth: '293px',
            position: 'absolute',
            right: 0,
            left: 0,
            margin: 'auto',
        },
        dividerArrow: {
            position: 'absolute',
            maxHeight: '50%',
            right: 0,
            top: 22,
            left: 0,
            margin: 'auto',
        },
        searchWorkerRoot: {
            fontFamily: 'century-gothic',
            minHeight: '370px',
            width: '100%',
            backgroundColor: '#f2f2f2',
        },
        searchTextWorkerGrid: {
            paddingTop: theme.spacing(8),
            height: '100%',
            position: 'relative',
            textAlign: 'end',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        searchWorkerTitle: {
            fontWeight: 'bold',
            fontSize: 33,
            zIndex: 2,
            color: '#966dff',
        },
        searchWorkerDescription: {
            paddingTop: theme.spacing(2),
            fontSize: 21,
            zIndex: 2,
            color: '#343434',
        },
        searchWorkerButtonArea: {
            paddingTop: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
               paddingBottom: '20px',
            },
        },
        searchImageWrapped: {
            paddingTop: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        ideaRoot: {
            'overflow': 'hidden',
            'position': 'relative',
            'minHeight': '350px',
            'width': '100%',
            'fontFamily': 'century-gothic',
            'backgroundImage': 'linear-gradient(-90deg, #859afe, #966dff)',
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/home/DIPcom_kolecka.png)',
                position: 'absolute',
                right: '180px',
                bottom: 0,
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                },
            },
        },
        ideaTextGrid: {
            paddingTop: theme.spacing(15),
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        ideaTextTitle: {
            fontWeight: 'bold',
            fontSize: 41,
            zIndex: 2,
            color: colors.common.white,
        },
        ideaTextDescription: {
            paddingTop: theme.spacing(6),
            fontSize: 21,
            zIndex: 2,
            color: colors.common.white,
        },
        ideaImageWrapped: {
            display: 'flex',
            position: 'relative',
            paddingTop: theme.spacing(2.5),
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        ideaImage: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        resultRoot: {
            fontFamily: 'century-gothic',
            backgroundColor: colors.common.white,
            minHeight: '600px',
            paddingBottom: '60px',
        },
        resultTitle: {
            'paddingTop': 60,
            'color': '#966dff',
            'fontSize': 42,
            'fontWeight': 'bold',
            'textAlign': 'center',
            'position': 'relative',
            '&::before' : {
                height: '3px',
                width: '105px',
                margin: 'auto',
                backgroundColor: '#dcdcdc',
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-10px',
            },
        },
        resultOneWrapped: {
            paddingTop: '90px',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap-reverse',
            },
        },
        resultOneLeft: {
            textAlign: 'end',
            paddingRight: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px',
                paddingRight: 0,
                textAlign: 'center',
            },
        },
        resultOneRight: {
            paddingLeft: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                textAlign: 'center',
            },
        },
        resultOneRightImage: {
            width: '88px',
            height: '82px',
            backgroundImage: 'url(/images/home/kolecko_s_sipkou_1.png)',
            position: 'relative',
        },
        resultOneRightImageText: {
            color: colors.common.white,
            fontSize: 42,
            fontWeight: 'bold',
            position: 'absolute',
            top: 0,
            left: '7px',
            textAlign: 'center',
            right: 0,
            lineHeight: '82px',
        },
        resultOneRightTitle: {
            paddingTop: '25px',
            fontSize: 25,
            color: '#966dff',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        resultOneRightDescription: {
            paddingTop: '20px',
            fontSize: 21,
            color: '#343434',
        },
        resultTwoWrapped: {
            paddingTop: '80px',
        },
        resultTwoLeft: {
            textAlign: 'end',
            paddingRight: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                textAlign: 'center',
            },
        },
        resultTwoRight: {
            paddingLeft: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px',
                paddingLeft: 0,
                textAlign: 'center',
            },
        },
        resultTwoLeftImage: {
            display: 'inline-block',
            width: '88px',
            height: '82px',
            backgroundImage: 'url(/images/home/kolecko_s_sipkou_2.png)',
            position: 'relative',
        },
        resultTwoLeftImageText: {
            color: colors.common.white,
            fontSize: 42,
            fontWeight: 'bold',
            position: 'absolute',
            top: 0,
            right: '7px',
            textAlign: 'center',
            left: 0,
            lineHeight: '82px',
        },
        resultTwoRightTitle: {
            paddingTop: '25px',
            fontSize: 25,
            color: '#966dff',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        resultTwoRightDescription: {
            paddingTop: '20px',
            fontSize: 21,
            color: '#343434',
        },
        contactRoot: {
            fontFamily: 'century-gothic',
            backgroundColor: '#f2f2f2',
            minHeight: '380px',
        },
        contactTitle: {
            'textTransform': 'uppercase',
            'paddingTop': 60,
            'color': '#859afe',
            'fontSize': 42,
            'fontWeight': 'bold',
            'textAlign': 'center',
            'position': 'relative',
            '&::before' : {
                height: '3px',
                width: '105px',
                margin: 'auto',
                backgroundColor: '#dcdcdc',
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-10px',
            },
        },
        contactTitleTwo: {
            textAlign: 'center',
            textTransform: 'uppercase',
            paddingTop: 40,
            color: '#343434',
            fontSize: 30,
            fontWeight: 'bold',
        },
        contactDescription: {
            fontSize: 21,
            paddingTop: 10,
            textAlign: 'center',
            color: '#343434',
        },
        contactIconsRoot: {
            fontFamily: 'century-gothic',
            backgroundColor: colors.common.white,
            minHeight: '305px',
            paddingBottom: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 'unset',
            },
        },
        contactIconGrid: {
            minHeight: '280px',
            paddingRight: '100px',
            paddingLeft: '100px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 'unset',
                paddingLeft: 'unset',
            },
        },
        contactIconBubble: {
            top: '-58px',
            left: 0,
            right: 0,
            margin: 'auto',
            position: 'absolute',
            height: '120px',
            width: '120px',
            backgroundImage: 'linear-gradient(-90deg, #9967ff, #8e82fe)',
            borderRadius: '50%',
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: '170px',
        },
        contactIconTitle: {
            fontSize: 21,
            paddingTop: '90px',
            color: '#858585',
            textAlign: 'center',
        },
        contactIconDescription: {
            fontSize: 21,
            // paddingTop: '25px',
            color: '#343434',
            textAlign: 'center',
        },
        contactIconButton: {
            paddingTop: '25px',
            textAlign: 'center',
        },
        contactIconGridItem: {
            position: 'relative',
            height: '100%',
        },
        contactIconBottomArea: {
            paddingTop: '20px',
            height: '200px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '80px',
                height: 'unset',
            },
        },
        mapRoot: {
            minHeight: '600px',
        },
    }),
);

export default withRouter(HomePage);
// export default HomePage;
