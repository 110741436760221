import {Reducer} from 'react';
import {RequestActions} from '../actions';

export interface IRequestState {
    sendingRequest: boolean;
    inProgress: string[];
}

const InitialState: IRequestState = {
    sendingRequest: false,
    inProgress: [],
};

export const request: Reducer<IRequestState, RequestActions> = (
    state = InitialState, action: RequestActions,
) => {

    switch (action.type) {
        case 'REQUEST_STARTED':
            return {state, sendingRequest: true, inProgress: [...state.inProgress, action.payload.requestFrom]};
        case 'REQUEST_FAILED':
            const stillInProgress = state.inProgress.filter((item) => item !== action.payload.requestFrom);
            return {state, sendingRequest: false, inProgress: stillInProgress};
        case 'REQUEST_FINISHED':
            return {state, sendingRequest: false, inProgress: [...state.inProgress, action.payload.requestFrom]};
        default:
            return state;
    }
};
