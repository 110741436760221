import React, {useRef, useState} from 'react';
import {TextField, TextFieldProps, Grid, makeStyles, Theme, createStyles} from '@material-ui/core';
import {InputType, SizingInterface} from '../BaseForm';
import {Validator} from 'redux-form/lib/Field';
import MyBlueButton from "../../../themes/components/MyBlueButton";

export interface FileInputType extends InputType {
    textFieldProps?: TextFieldProps;
}

export function GetFileInput(name: string, textFieldProps?: TextFieldProps, sizing?: SizingInterface, validate?: Validator[]): FileInputType {
    return {
        name,
        sizing,
        textFieldProps,
        validate,
        component: FileInput,
    };
}

const FileInput: React.FC = (field: any | FileInputType) => {
    const {textFieldProps} = field;
    const classes = useStyles();
    const fileInputRef = useRef(null);

    const onChangeFile = (file: File) => {
        const url = URL.createObjectURL(file);
        field.input.onChange({name: file.name, url});
    }

    return (
        <React.Fragment>
            <Grid
                item
                container
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                className={classes.wrapped}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={7}
                    className={classes.inputWrapped}
                >
                    <TextField
                        className={'myTextField'}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        error={field.meta.touched && field.meta.error ? true : false}
                        helperText={field.meta.touched && field.meta.error}
                        {...textFieldProps}

                        value={field.input.value ? field.input.value.name : ''}
                        onClick={(event) => {
                            fileInputRef.current.click();
                        }}
                    />
                    <input onChange={({target}) => {
                        onChangeFile(target.files[0]);
                        target.value = '';
                    }} ref={fileInputRef} style={{display: 'none'}} type={'file'} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={5}
                >
                    <MyBlueButton
                        fullWidth
                        onClick={() => {
                            fileInputRef.current.click();
                        }}
                        className={classes.button}
                    > Vybrat soubor
                    </MyBlueButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: '16px',
            lineHeight: '53px',
        },
        inputWrapped: {
            paddingRight: '20px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '0',
            },
        },
        wrapped: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '20px',
            },
        },
    }),
);


export default FileInput;
