import {Reducer} from 'react';
import {MenuActions} from '../actions';

export interface IMenuState {
    fixed: boolean;
}

const InitialState: IMenuState = {
    fixed: false,
};

export const menu: Reducer<IMenuState, MenuActions> = (
    state = InitialState, action: MenuActions,
) => {

    switch (action.type) {
        case 'MENU_CHANGE':
            return {...state, ...action.payload};
        default:
            return state;
    }
};
