import React, {useEffect, useRef} from 'react';
import Page from '../../../core/components/Page';
import {colors, Container, Grid, Hidden, makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {changeMenuState} from '../../../core/actions/menu';
import {IAppState} from '../../../core/reducers';
import Footer from '../../Footer';
import MyBlueButton from '../../../core/themes/components/MyBlueButton';
import CarrierForm, {CarrierFormData} from '../../components/forms/CarrierForm';
import {baseRequestAction} from '../../../core/actions/request';
import {sendCarrierForm} from '../../api/api.homepage';
import {Notification} from '../../../core/components/notification/Notify';
import {reset, stopSubmit} from 'redux-form';
import {scroller} from 'react-scroll';

let timeout;

function CarrerPage() {
    const classes = HomePageStyles({});
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const menu = useSelector((state: IAppState) => state.menu);
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            if (scrollRef.current) {
                const elementTopPosition = scrollRef.current.offsetTop;
                const windowScrollTopPosition = window.scrollY;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    if (windowScrollTopPosition >= elementTopPosition) {
                        if (menu.fixed === true) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: true,
                        }));
                    } else {
                        if (menu.fixed === false) {
                            return;
                        }
                        dispatch(changeMenuState({
                            fixed: false,
                        }));
                    }
                }, 5);
            }
        });
    }, [menu]);

    const handleSubmit = (formData: CarrierFormData) => {
        dispatch(baseRequestAction({
            requestFrom: 'WantApplication',
            onRequestStarted: async (data) => {
                return await sendCarrierForm(formData);
            },
            onRequestSuccess: (data) => {
                Notification.createNotification('Odesláno!', {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    variant: 'success',
                });

                dispatch(reset('CarrierForm'));
            },
            onRequestFailed: (data) => {
                const formFieldErrors = {};
                // @ts-ignore
                if (data.error.response && data.error.response.ValidationError && Array.isArray(data.error.response.ValidationError)) {
                    // @ts-ignore
                    for (const {property, messages} of data.error.response.ValidationError) {
                        formFieldErrors[property] = messages[0];
                    }
                }
                console.log('formFieldErrors', formFieldErrors);
                if (formFieldErrors) {
                    dispatch(stopSubmit('CarrierForm', formFieldErrors));
                }
            },
        }));
    };

    return <Page
        title={'Apps'}
    >
        <div className={classes.root}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    className={classes.gridRoot}
                    container
                    direction='column'
                    justify='center'
                    alignItems='flex-start'
                >
                    <Grid item className={classes.rootTextTitle}>
                        Kariéra...
                    </Grid>
                    <Grid item className={classes.rootTextTitleTwo}>
                        Ve svém týmu uvítáme nové parťáky.
                    </Grid>
                    <Grid item className={classes.rootTextTitleTwo}>
                        Připojíš se k nám právě ty?
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.divider} ref={scrollRef}>
            <div className={classes.dividerWave}>
                <img src={'/images/home/vlnka.png'} style={{maxWidth: '100%'}}/>
                <img className={classes.dividerArrow} src={'/images/home/sipky.png'} onClick={() => {
                    scroller.scrollTo('arrowClick', {
                        duration: 1000,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                    });
                }}/>
            </div>
        </div>
        <div className={classes.programmerRoot} id={'arrowClick'}>
            <Container maxWidth={'lg'} style={{height: '100%'}} className={classes.programmerContainer}>
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                    >
                        <Grid item>
                            <MyBlueButton
                                classes={{
                                    label: classes.uppercase,
                                }}
                                style={{lineHeight: '60px'}}>
                                PROGRAMÁTOR {'</>'}
                            </MyBlueButton>
                        </Grid>
                        <Grid item className={classes.programmerTopText}>
                            Na pozici programátora hledáme zdatného vývojáře,
                            který má chuť realizovat nové projekty.
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                    >
                        <Grid
                            item
                            container
                            direction='column'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.programmerRow}
                            xs={12}
                            md={6}
                        >
                            <Grid item className={classes.programmerTitle}>
                                Náplň práce:
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Vývoj webových aplikací pro naše
                                zákazníky
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Rozšiřování stávajících aplikací,
                                implementace nových funkcí a modulů
                            </Grid>

                            <Grid item className={classes.programmerTitle}>
                                Požadujeme:
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Dobrou znalost Node.js, MySQL, nebo jinou
                                DB platformu
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Výhodou React, NestJS, GraphQL, TypeScript,
                                Elasticsearch
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Zájem o nové trendy a technologie
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Chuť učit se a nebát se náročných výzev
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Práci v týmu
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction='column'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.programmerRowTwo}
                            xs={12}
                            md={6}
                        >
                            <Grid item className={classes.programmerTitle}>
                                Nabízíme:
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Flexibilní pracovní dobu
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Dle schopností možnost home office
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Příjemné pracovní prostředí
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Práci v mladém, neformálním kolektivu
                                a přátelské atmosféře
                            </Grid>
                            <Grid item className={classes.programmerPoint}>
                                Platové ohodnocení 30 000–60 000
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.resultRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                >
                    <Grid
                        item
                        className={classes.resultTitle}
                        xs={12}
                    >
                        Sedneme si?
                    </Grid>
                    <Grid
                        item
                        className={classes.resultDescription}
                        xs={12}
                    >
                        Jsi náš nový kolega? To zjistíme v několika krocích.
                    </Grid>
                    <Grid
                        item
                        className={classes.resultDescription}
                        xs={12}
                    >
                        Musí to fungovat oboustranně – tak jako ty by ses měl líbit nám, my bychom se měli líbit tobě.
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.resultOneWrapped}
                    >
                        <Grid
                            item
                            xs={12}
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.resultRow}
                        >
                            <Grid item xs={12} md={5} className={classes.resultBubble}>
                                <Hidden smDown>
                                    <div className={classes.resultOneRightImage}>
                                        <div className={classes.resultOneRightImageText}>
                                            01
                                        </div>
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} md={7} className={classes.resultWhiteWrapped}>
                                <div className={classes.resultOneRightTitle}>
                                    Podíváme se na tvoje CV
                                </div>
                                <div className={classes.resultOneRightDescription}>
                                    Ukaž nám, co umíš, co už máš za sebou, co tě baví a v čem nám můžeš být přínosem.
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.resultRow}
                        >
                            <Grid item xs={12} md={7} className={classes.resultWhiteWrapped}>
                                <div className={classes.resultOneRightTitle}>
                                    Když se nám zalíbíš, sejdeme se
                                </div>
                                <div className={classes.resultOneRightDescription}>
                                    První setkání je pohodové, neformální popovídání o našich a tvých představách spolupráce. Sako nech ve skříni, ani my ho mít nebudeme.
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12} className={classes.resultBubbleTwo}>
                                <Hidden smDown>
                                    <div className={classes.resultOneRightImageTwo}>
                                        <div className={classes.resultOneRightImageTextTwo}>
                                            02
                                        </div>
                                    </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.resultRow}
                        >
                            <Grid item md={5} xs={12} className={classes.resultBubble}>
                                <Hidden smDown>
                                    <div className={classes.resultOneRightImage}>
                                        <div className={classes.resultOneRightImageText}>
                                            03
                                        </div>
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} md={7} className={classes.resultWhiteWrapped}>
                                <div className={classes.resultOneRightTitle}>
                                    Vybereme si tě?
                                </div>
                                <div className={classes.resultOneRightDescription}>
                                    Pokud vše klapne, ozveme se ti a domluvíme další kroky.
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            className={classes.resultRow}
                        >
                            <Grid item xs={12} md={7} className={classes.resultWhiteWrapped}>
                                <div className={classes.resultOneRightTitle}>
                                    První den v práci
                                </div>
                                <div className={classes.resultOneRightDescription}>
                                    A už je to tady! První den v práci se těš na seznámení s týmem a všemi projekty.
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12} className={classes.resultBubbleTwo}>
                                <Hidden smDown>
                                    <div className={classes.resultOneRightImageTwo}>
                                        <div className={classes.resultOneRightImageTextTwo}>
                                            04
                                        </div>
                                    </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div className={classes.searchRoot} id={'carrierForm'}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                    // className={classes.imageBackground}
                >
                    <Grid item xs={12} className={classes.searchTitle}>
                        Ozvi se nám!
                    </Grid>
                    <Grid item xs={12} className={classes.searchTitleTwo}>
                        Napiš nám,
                    </Grid>
                    <Grid item xs={12} className={classes.searchDescription}>
                        ozveme se.
                    </Grid>
                    <Grid item xs={12} className={classes.searchForm}>
                        <CarrierForm onSubmit={handleSubmit.bind(this)}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <Footer/>
    </Page>;
}

const HomePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconsWrapped: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '30px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        uppercase: {
            textTransform: 'uppercase',
        },
        iconsScroll: {
            height: '110px',
            overflow: 'auto',
        },
        icon: {
            // verticalAlign: 'top',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        // root: {
        //     paddingTop: theme.spacing(10),
        //     height: '410px',
        //     fontFamily: 'century-gothic',
        //     backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
        //     color: colors.common.white,
        //     position: 'relative',
        //     textAlign: 'center',
        //     overflow: 'hidden',
        //     [theme.breakpoints.down('sm')]: {
        //         minHeight: '410px',
        //         paddingTop: '100px',
        //         height: 'unset',
        //     },
        //
        // },

        root: {
            paddingTop: theme.spacing(5),
            height: '485px',
            fontFamily: 'century-gothic',
            backgroundImage: 'linear-gradient(-90deg, #859afe, #966dff)',
            color: colors.common.white,
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(8),
                minHeight: '410px',
                paddingTop: '100px',
                height: 'unset',
            },
        },

        programmerRow: {
            zIndex: 2,
            paddingTop: '25px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            },
        },
        programmerRowTwo: {
            zIndex: 2,
            paddingTop: '25px',
            paddingLeft: '100px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 'unset',
                textAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            },
        },
        programmerTitle: {
            zIndex: 2,
            paddingTop: '40px',
            color: '#859afe',
            fontSize: '21px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        programmerPoint: {
            'textAlign' : 'start',
            'fontSize': '21px',
            'paddingTop': '25px',
            'paddingLeft': '40px',
            'position': 'relative',
            'zIndex': 3,
            '&::before' : {
                zIndex: 1,
                height: '15px',
                width: '15px',
                backgroundColor: '#859afe',
                [theme.breakpoints.down('sm')]: {
                    content: 'unset',
                },
                content: '\'\'',
                borderRadius: '50%',
                position: 'absolute',
                left: 0,
                top: '30px',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingLeft: '0',
            },
        },
        programmerTopText: {
            zIndex: 2,
            fontSize: '21px',
            paddingTop: '40px',
            paddingLeft: '150px',
            paddingRight: '150px',
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '50px',
                paddingRight: '50px',
            },
        },

        programmerContainer: {
            'paddingTop': theme.spacing(10),
            'paddingBottom': theme.spacing(18),
            'position': 'relative',
            'overflow': 'hidden',
            'zIndex': 2,
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/programator_obrazek.png)',
                [theme.breakpoints.down('sm')]: {
                    content: 'unset',
                },
                position: 'absolute',
                right: 0,
                bottom: '-5px',
            },
        },
        programmerRoot: {

            minHeight: '410px',
            fontFamily: 'century-gothic',
            backgroundColor: colors.common.white,
            color: '#343434',
            textAlign: 'center',
        },
        rootTextTitle: {
            zIndex: 2,
            fontWeight: 'bold',
            fontSize: 42,
            textAlign : 'start',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        rootTextTitleTwo: {
            paddingTop: '10px',
            zIndex: 2,
            fontWeight: 'bold',
            fontSize: 33,
            textAlign: 'start',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        rootTextDescription: {
            marginTop: theme.spacing(3),
            fontSize: 18,
            zIndex: 2,
        },
        gridRoot: {
            // textAlign: 'start',
            'fontFamily': 'century-gothic',
            'height': '100%',
            'position': 'relative',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/sipka.png)',
                position: 'absolute',
                left: 0,
                right: '-500px',
                bottom: '100px',
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                    bottom: '-50px',
                },
            },
        },
        divider: {
            height: '13px',
            backgroundColor: colors.common.white,
            width: '100%',
            position: 'relative',
        },
        dividerWave: {
            bottom: 7,
            zIndex: 2,
            width: '100%',
            maxWidth: '293px',
            position: 'absolute',
            right: 0,
            left: 0,
            margin: 'auto',
        },
        dividerArrow: {
            position: 'absolute',
            maxHeight: '50%',
            right: 0,
            top: 22,
            left: 0,
            margin: 'auto',
        },
        resultRoot: {
            fontFamily: 'century-gothic',
            backgroundColor: '#f2f2f2',
            minHeight: '600px',
            paddingBottom: '80px',
        },
        resultDescription: {
            textAlign: 'center',
            fontSize: '21px',
            color: '#343434',
            paddingTop: '10px',
            paddingLeft: '100px',
            paddingRight: '100px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '50px',
                paddingRight: '50px',
            },
        },
        resultRow: {
            paddingTop: '50px',
        },
        resultTitle: {
            'paddingTop': 60,
            'marginBottom': 60,
            'color': '#966dff',
            'fontSize': 42,
            'fontWeight': 'bold',
            'textAlign': 'center',
            'position': 'relative',
            '&::before' : {
                height: '3px',
                width: '105px',
                margin: 'auto',
                backgroundColor: '#dcdcdc',
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-10px',
            },
        },
        resultOneWrapped: {
            paddingTop: '60px',
            [theme.breakpoints.down('sm')]: {
                // flexWrap: 'wrap-reverse',
            },
        },
        resultOneLeft: {
            textAlign: 'end',
            paddingRight: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px',
                paddingRight: 0,
                textAlign: 'center',
            },
        },
        resultOneRight: {
            paddingLeft: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                textAlign: 'center',
            },
        },
        resultBubble: {
            paddingRight: '30px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        resultBubbleTwo: {
            paddingLeft: '30px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        resultWhiteWrapped: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
            padding: '30px 30px 30px 30px',
            backgroundColor: colors.common.white,
        },
        resultOneRightImage: {
            width: '131px',
            height: '121px',
            backgroundImage: 'url(/images/kariera/kolecko_s_sipkou_1.png)',
            position: 'relative',
        },
        resultOneRightImageTwo: {
            width: '131px',
            height: '121px',
            backgroundImage: 'url(/images/kariera/kolecko_s_sipkou_2.png)',
            position: 'relative',
        },
        resultOneRightImageText: {
            color: colors.common.white,
            fontSize: 50,
            fontWeight: 'bold',
            position: 'absolute',
            top: 0,
            left: '-8px',
            textAlign: 'center',
            right: 0,
            lineHeight: '121px',
        },
        resultOneRightImageTextTwo: {
            color: colors.common.white,
            fontSize: 50,
            fontWeight: 'bold',
            position: 'absolute',
            top: 0,
            left: '8px',
            textAlign: 'center',
            right: 0,
            lineHeight: '121px',
        },
        resultOneRightTitle: {
            fontSize: 25,
            color: '#966dff',
            fontWeight: 'bold',
        },
        resultOneRightDescription: {
            paddingTop: '20px',
            fontSize: 21,
            color: '#343434',
        },
        resultTwoWrapped: {
            paddingTop: '60px',
        },
        resultTwoLeft: {
            textAlign: 'end',
            paddingRight: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                textAlign: 'center',
            },
        },
        resultTwoRight: {
            paddingLeft: '60px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px',
                paddingLeft: 0,
                textAlign: 'center',
            },
        },
        resultTwoLeftImage: {
            display: 'inline-block',
            width: '88px',
            height: '82px',
            backgroundImage: 'url(/images/home/kolecko_s_sipkou_2.png)',
            position: 'relative',
        },
        resultTwoLeftImageText: {
            color: colors.common.white,
            fontSize: 42,
            fontWeight: 'bold',
            position: 'absolute',
            top: 0,
            right: '7px',
            textAlign: 'center',
            left: 0,
            lineHeight: '82px',
        },
        resultTwoRightTitle: {
            paddingTop: '25px',
            fontSize: 25,
            color: '#966dff',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        resultTwoRightDescription: {
            paddingTop: '20px',
            fontSize: 21,
            color: '#343434',
        },
        searchRoot: {
            paddingTop: '60px',
            textAlign: 'center',
            color: '#343434',
            fontFamily: 'century-gothic',
            minHeight: '340px',
            width: '100%',
            backgroundColor: colors.common.white,
            paddingBottom: '90px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '80px',
                paddingLeft: '80px',
                paddingBottom: '50px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: '20px',
                paddingLeft: '20px',
                paddingBottom: '50px',
            },
        },
        searchBackGroundImage: {
            'position': 'relative',
            'zIndex': 2,
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/obrazek_email.png)',
                position: 'absolute',
                right: '120px',
                bottom: '80px',
            },
        },
        searchTitle: {
            'zIndex': 3,
            'textTransform': 'uppercase',
            'color': '#859afe',
            'fontSize': 42,
            'fontWeight': 'bold',
            'textAlign': 'center',
            'position': 'relative',
            '&::before' : {
                height: '3px',
                width: '105px',
                margin: 'auto',
                backgroundColor: '#dcdcdc',
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: '-10px',
            },
        },
        searchTitleTwo: {
            zIndex: 3,
            paddingTop: 30,
            color: '#343434',
            fontSize: 29,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        searchDescription: {
            zIndex: 3,
            paddingTop: '10px',
            fontSize: 21,
        },
        searchForm: {
            'zIndex': 3,
            'paddingTop': '60px',
            // paddingRight: '190px',
            // paddingLeft: '190px',

            // [theme.breakpoints.down('sm')]: {
            //     paddingRight: '80px',
            //     paddingLeft: '80px',
            // },
            // [theme.breakpoints.down('xs')]: {
            //     paddingRight: '20px',
            //     paddingLeft: '20px',
            // },
            'position': 'relative',
            '&::before' : {
                zIndex: 1,
                content: 'url(/images/kariera/obrazek_email.png)',
                position: 'absolute',
                right: '120px',
                bottom: 0,
                [theme.breakpoints.down('sm')]: {
                    content: 'unset',
                },
            },
        },
    }),
);

export default CarrerPage;
