import React, {useEffect} from 'react';
import {colors, Container, Grid, Link, makeStyles} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {createStyles, Theme} from '@material-ui/core/styles';
import {animateScroll as scroll, scroller} from 'react-scroll';

const Footer = () => {

    const classes = Styles();
    const goToTop = () => {
        setTimeout(() => {
            scroll.scrollToTop();
        }, 100);
    };

    useEffect(() => {
        setTimeout(() => {
            scroller.scrollTo('contact', {
                duration: 1000,
                delay: 0,
                smooth: 'easeInOutQuart',
            });
        }, 500);
    }, []);

    return (
        <div className={classes.footerRoot}>
            <Container maxWidth={'lg'} style={{height: '100%'}}>
                <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='flex-start'
                    className={classes.footerGrid}
                >
                    <Grid
                        className={classes.footerGridItem}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <span style={{
                            textTransform: 'uppercase',
                        }}>
                            Fakturační údaje
                        </span>
                        <br/><br/>
                        DIPcom s.r.o.<br/>
                        IČ: 28154568<br/>
                        DIČ: CZ28154568<br/>
                        Bú:259437252/0300<br/>
                        fakturace@dipcom.cz
                    </Grid>
                    <Grid
                        className={classes.footerGridItem}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <span style={{
                            textTransform: 'uppercase',
                        }}>
                            Sídlo
                        </span>
                        <br/><br/>
                        Kněžskodvorská 2296/8a<br/>
                        České Budějovice<br/>
                        370 04
                    </Grid>
                    <Grid
                        className={classes.footerGridItem}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <Link
                            component={RouterLink}
                            to='/'
                            className={classes.link}
                            onClick={(e) => {
                                goToTop();
                            }}
                        >
                            Úvod
                        </Link> <br/>
                        <Link
                            component={RouterLink}
                            to='/about-us'
                            onClick={(e) => {
                                goToTop();
                            }}
                            className={classes.link}
                        >
                            Kdo jsme
                        </Link> <br/>
                        <Link
                            component={RouterLink}
                            to='apps'
                            className={classes.link}
                            onClick={(e) => {
                                goToTop();
                            }}
                        >
                            Aplikace
                        </Link> <br/>
                        <Link
                            component={RouterLink}
                            to='/?contact=true'
                            onClick={(e) => {
                                setTimeout(() => {
                                    scroller.scrollTo('contact', {
                                        duration: 1000,
                                        delay: 0,
                                        smooth: 'easeInOutQuart',
                                    });
                                }, 500);
                            }}
                            className={classes.link}
                        >
                            Kontakt
                        </Link> <br/>
                        <Link
                            component={RouterLink}
                            to='career'
                            onClick={(e) => {
                                goToTop();
                            }}
                            className={classes.link}
                        >
                            Kariéra
                        </Link> <br/>
                    </Grid>
                    <Grid
                        className={classes.footerGridItem}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <span style={{
                            textTransform: 'uppercase',
                        }}>
                            Najdete nás také
                        </span>
                        <Grid
                            className={classes.footerGridItemIcons}
                            container
                            direction='row'
                            justify='center'
                            alignItems='flex-start'
                            style={{paddingTop: '30px'}}
                        >
                            <a target='_blank' href={'https://www.facebook.com/www.dipcom.cz/'}>
                                <img src={'/images/home/fb.png'} style={{maxWidth: '100%'}}/>
                            </a>
                            <a target='_blank' href={'https://github.com/DIPcom'}>
                                <img src={'/images/home/git.png'} style={{maxWidth: '100%'}}/>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid
                        className={classes.footerGridItemLast}
                        item
                        xs={12}
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <img src={'/images/home/D_paticka.png'} style={{maxWidth: '100%'}}/>
                        <img src={'/images/home/C.png'} style={{maxWidth: '100%', paddingLeft: '5px', paddingRight: '5px'}}/>
                        2013 - 2021, DIPcom s.r.o. - IT Technology
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const Styles = makeStyles((theme: Theme) =>
    createStyles({
        footerRoot: {
            paddingTop: '40px',
            fontFamily: 'century-gothic',
            fontSize: 17,
            minHeight: '300px',
            backgroundColor: '#966dff',
            color: colors.common.white,
        },
        footerGrid: {
            paddingTop: '40px',
        },
        link: {
            color: colors.common.white,
            fontFamily: 'century-gothic',
        },
        footerGridItem: {
            'paddingLeft': '50px',
            '&:first-child': {
                paddingLeft: 0,
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '20px',
                textAlign: 'center',
                paddingLeft: 0,
            },
        },
        footerGridItemIcons: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '75px',
                paddingRight: '75px',
            },
        },
        footerGridItemLast: {
            fontSize: 14,
            paddingTop: '50px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
                paddingBottom: '20px',
                textAlign: 'center',
                justifyContent: 'center',
            },
        },
    }),
);

export default Footer;
