import {createStore, compose, applyMiddleware, Store} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware, {END} from 'redux-saga';
import sagas from '../sagas';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import rootReducer, {IAppState} from '../reducers';
import _ from 'lodash';
import Thunk from 'redux-thunk';
import {loadState, saveState} from './localStorage';
const persistedState = loadState();
const loggerMiddleware = createLogger({
    predicate: (getState, action) => !action.type.includes('@@redux-form'),
});
const sagaMiddleware = createSagaMiddleware();

function configureStoreProd(): Store<IAppState> {
    const middlewares = [
        Thunk,
        sagaMiddleware,
    ];

    const store = createStore(rootReducer, persistedState, compose(
        applyMiddleware(...middlewares),
        ),
    );

    // store.subscribe(_.throttle(() => {
    //     saveState({
    //         loggedUser: store.getState().loggedUser,
    //     });
    // }, 0));

    sagaMiddleware.run(sagas);
    // store.close = () => store.dispatch(END);

    return store;
}

function configureStoreDev(): Store<IAppState> {

    const middlewares = [
        reduxImmutableStateInvariant(),
        sagaMiddleware,
        loggerMiddleware,
    ];

    const composeEnhancers = compose; // add support for Redux dev tools
    const store = createStore(rootReducer, persistedState, composeEnhancers(
        applyMiddleware(...middlewares),
        ),
    );

    // store.subscribe(_.throttle(() => {
    //     saveState({
    //         loggedUser: store.getState().loggedUser,
    //     });
    // }, 0));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }

    sagaMiddleware.run(sagas);
    // store.close = () => store.dispatch(END);

    return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
